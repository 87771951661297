import React from "react";
import { UserPhoto } from "../../common/user";
import { useAuthUser } from "../../../hooks/auth/useAuthUser";

/**
 * Controls the shape of props offered by MenuUserSection component.
 */
export interface IMenuUserSectionProps {}

/**
 * Helps keep the classes and styles of MenuUserSection component protected.
 * Also, elevates the simplicity and maintainability of the UserPhoto rendered within.
 *
 * @param props Input props
 * @returns React.FC
 */
export const MenuUserSection: React.FC<IMenuUserSectionProps> = () => {
	const { userDisplayName, userPrincipalName } = useAuthUser();

	return (
		<div className="nav navbar-nav navbar-right login-margin-right">
			<UserPhoto userPrincipalName={userPrincipalName} className="headIcon" size="lg" />
			<a className="ml-2 hide-on-mobile" href="#">
				{userDisplayName}
			</a>
		</div>
	);
};
