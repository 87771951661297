import { INodeSlice } from "../../../contracts/slices/node/INodeSlice";

/**
 * The Initial State of Node Management slice.
 */
export const nodeInitialState: INodeSlice = {
	queried: {},
	querying: {},
	list: [],
	photos: {},
	ui: {
		isOpenDeleteConfirmation: false,
		isOpenStickConfirmation: false,
		isOpenPrivateConfirmation: false,
		isActionConfirmed: false,
		deleteConfirmationModalTitle: "",
		deleteConfirmationModalSubText: "",
		deleteConfirmationModalButtonText: "",
		confirmationModalAction: null,
		confirmationStickyAction: null,
		isOpenActionPostMessage: false,
		isChangeSpacePanelOpen: false,
		isNodeSpaceSticky: false,
		isNodeSiteSticky: false,
		isOpenShareContent: false,
		isSubmittingShareContent: false,
		isNodePrivate: false,
		isNodeDeleted: false
	}
};
