/**
 * Redux config to manage space Form data
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { spaceFormInitialState } from "../../models/slices/spaces/spaceFormInitialState";
import { ISpaceFormData } from "../../contracts";

/**
 * Article Form state management layer, also named after a Redux Slice.
 */
export const spaceFormSlice = createSlice({
	name: AppSlices.spaceForm,
	initialState: spaceFormInitialState,
	reducers: {
		setSelectedParentId: (state, action: PayloadAction<null | number | string>) => {
			state.selectedParentId = action.payload;
		},
		resetSelectedParentId: (state) => {
			state.selectedParentId = spaceFormInitialState.selectedParentId;
		},
		setData: (state, action: PayloadAction<ISpaceFormData>) => {
			state.data = action.payload;
		},
		resetData: (state) => {
			state.data = spaceFormInitialState.data;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setLoaded: (state, action: PayloadAction<boolean>) => {
			state.loaded = action.payload;
		},
		setIsDataLoading: (state, action: PayloadAction<boolean>) => {
			state.dataLoading = action.payload;
		},
		setIsDataLoaded: (state, action: PayloadAction<boolean>) => {
			state.dataLoaded = action.payload;
		},
		setManageFormDataListIsLoading: (state, action: PayloadAction<boolean>) => {
			state.dataManageListLoading = action.payload;
		},
		setManageFormDataHeaderIsLoading: (state, action: PayloadAction<boolean>) => {
			state.dataManageHeaderLoading = action.payload;
		},
		setManageFormDataListIsLoaded: (state, action: PayloadAction<boolean>) => {
			state.dataManageListLoaded = action.payload;
		},
		setManageFormDataHeaderIsLoaded: (state, action: PayloadAction<boolean>) => {
			state.dataManageHeaderLoaded = action.payload;
		},
		setCoverImageFile: (state, action: PayloadAction<string>) => {
			state.coverImageFile = action.payload;
		}
	}
});

export const spaceFormActions = spaceFormSlice.actions;

export default spaceFormSlice.reducer;
