import { createSelector } from "@reduxjs/toolkit";
import { ISpaceFormData } from "../../contracts";
import { spacesSelectors } from "./spaces";

/**
 * Space Form data access selectors.
 */
export const spaceFormSelectors = {
	getData: (state): ISpaceFormData => {
		return state.spaceForm.data;
	},
	getIsLoading: (state): ISpaceFormData => {
		return state.spaceForm.loading;
	},
	getIsDataLoading: (state): boolean => {
		return state.spaceForm.dataLoading;
	},
	getIsDataLoaded: (state): boolean => {
		return state.spaceForm.dataLoaded;
	},
	getIsManageHeaderLoaded: (state): boolean => {
		return state.spaceForm.dataManageHeaderLoaded;
	},
	getIsManageHeaderLoading: (state): boolean => {
		return state.spaceForm.dataManageHeaderLoading;
	},
	getIsManageListLoaded: (state): boolean => {
		return state.spaceForm.dataManageListLoaded;
	},
	getIsManageListLoading: (state): boolean => {
		return state.spaceForm.dataManageListLoading;
	},
	getCoverImageFile: (state): string => {
		return state.spaceForm.coverImageFile;
	},
	getFormSelectedItem: createSelector(
		(state) => state,
		(state) => state.spaceForm.selectedParentId,
		(state, selectedId) => {
			let spaceById;
			const selectedSpaceId = selectedId;

			if (!selectedSpaceId) return null;

			spaceById = spacesSelectors.getSpaceById(state, selectedSpaceId);

			if (!spaceById || spaceById?.Title === "") return null;

			return {
				id: selectedSpaceId.toString(),
				text: spaceById?.Title
			};
		}
	)
};
