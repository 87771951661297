import React, { useEffect, useRef, useState } from "react";
import { EventCheckInDetails } from "./shared/event.show.event.checkin";
import { useSuperAdminAccessControl } from "../../hooks/auth/useSuperAdminAccessControl";
import { UnauthorizedPage } from "../common/page";
import { ServiceHub } from "../../service";

/**
 * Component Event Check-In to handle Events Check-In actions.
 */
const EventsCheckInPage: React.FC<{}> = () => {
	const { isSuperAdmin, requested, requesting } = useSuperAdminAccessControl();
	const [hasVolunteerAccess, setHasVolunteerAccess] = useState(false);
	const eventsAPI = useRef(ServiceHub.eventsAPI.start());
	const [volunteerAccessRequested, setVolunteerAccessRequested] = useState(false);

	useEffect(() => {
		if (!isSuperAdmin && requested) {
			eventsAPI.current
				.getVolunteerAccess()
				.then((result) => {
					if (result !== true) {
						setHasVolunteerAccess(false);
						return;
					}
					setHasVolunteerAccess(true);
					return;
				})
				.finally(() => {
					setVolunteerAccessRequested(true);
				});
		}
	}, [isSuperAdmin, requested, volunteerAccessRequested]);

	/**
	 * Shows unauthorized component
	 * @returns
	 */
	const renderUnauthorized = () => {
		return <UnauthorizedPage />;
	};

	if (isSuperAdmin || hasVolunteerAccess) {
		return <EventCheckInDetails />;
	}

	if (!isSuperAdmin && !hasVolunteerAccess && requested && !requesting && volunteerAccessRequested) {
		return renderUnauthorized();
	}

	return null;
};

export default EventsCheckInPage;
