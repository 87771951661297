import { IAppTreeViewSlice } from "../../../../contracts/slices";

/**
 * The Initial State of app Tree View component.
 */
export const appTreeViewInitialState: IAppTreeViewSlice = {
	expanded: [],
	loaded: false,
	rawData: [],
	loading: false,
};
