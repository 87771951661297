import React from "react";

/**
 * Standard required field marking,
 * for using within form-labels.
 *
 * @returns Span with an included asterisk, in red color.
 */
export const FieldRequiredMark: React.FC = () => {
	return (
		<span className="field-required-mark" tabIndex={0} aria-label="Required" role="text">
			*
		</span>
	);
};
