import React from "react";
import { SpaceForm } from "./shared/space.form";

/**
 * Component to serves as wrapper for Space Form component
 * @returns SpaceForm component
 */
const SpaceEditPage: React.FC = () => {
	return <SpaceForm />;
};

export default SpaceEditPage;
