import React, { useMemo } from "react";
import { PivotItem } from "@fluentui/react";
import { IPaginationProps, OverflowOption, Pagination } from "@coherence-design-system/pagination/lib";

/**
 * Contract for the Light Pagination component.
 */
interface IPaginationLightProps {
	totalPages: number;
	onPageChange: (pageIndex: number, pageNumber?: number | null) => void;
	pageLength?: undefined | number;
	page?: undefined | number;
}

/**
 * Light Strike application pagination component.
 * More or it should be controlled from outside
 *
 * @param props
 * @returns
 */
export const LightPagination: React.FunctionComponent<IPaginationLightProps> = ({
	pageLength = 15,
	page = 1,
	...props
}): React.ReactElement => {
	const totalPages = props.totalPages;

	// Pagination setup
	const [currentOverflowOption, setCurrentOverflowOption] = React.useState<OverflowOption | undefined>();
	const [currentPage, setCurrentPage] = React.useState(page);

	const handleOnPageChange = (_event: React.MouseEvent<HTMLButtonElement>, selectedPage: number): void => {
		setCurrentPage(selectedPage);

		props.onPageChange(selectedPage, pageLength);
	};

	const handleOverflowClick: NonNullable<IPaginationProps["overflowButtonProps"]>["onClick"] = (_event) => {
		const updateOverflow = currentOverflowOption ? undefined : "textfieldAndPageDropdown";
		setCurrentOverflowOption(updateOverflow);
	};

	const handlePivotClick = (
		pivot?: PivotItem | undefined,
		_ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined
	): void => {
		const currentNumber = parseInt((pivot && pivot.props.headerText) || "1");

		setCurrentPage(currentNumber);
		props.onPageChange(currentNumber, pageLength);
	};

	const paginationProps: IPaginationProps = useMemo(
		() => ({
			previousPage: {
				ariaLabel: "Previous Page"
			},
			nextPage: {
				ariaLabel: "Next Page"
			},
			overflowButtonProps: {
				onClick: handleOverflowClick
			},
			pageControls: {
				currentPage: currentPage,
				pages: totalPages,
				overflowOption: currentOverflowOption,
				onPivotClick: handlePivotClick
			},
			onPageChange: handleOnPageChange
			//itemsPerPageProps: {
			//  currentPageSize: currentPageSize,
			//  itemsPerPageList: itemsPerPageList,
			//  onItemsPerPageChange: handleOnItemsPerPageChange
			//}
		}),
		[currentPage, totalPages, currentOverflowOption]
	);

	return totalPages > 1 ? <Pagination {...paginationProps} /> : <div></div>;
};
