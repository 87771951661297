import { useEffect, useMemo, useRef, useState } from "react";
import { ServiceHub } from "../../service";
import { MiscellaneousKeys } from "../../contracts/models/strikeEnums";

/**
 * XHTML namespace pointer URL,
 * used across the file.
 */
const _xhtmlNamespace = "http://www.w3.org/1999/xhtml";

/**
 * Controls the return type of the HTML Virtualization hook.
 */
export interface IHtmlVirtualizationHook {
	virtualHtml: string;
	originalHtml: string;
	virtualElementIds: string[];
	virtualElements: Element[];
	originalElements: Element[];
	erroredElements: { elementType: string; error: string }[];
	onChange: (newContent: string) => void;
	onSyncOriginal: (realHtml: string, onComplete?: undefined | ((finalHtml: string) => void)) => void;
	onClearAll: () => void;
	onClearErrors: () => void;
}

/**
 * Virtualize an HTML passed,
 * transforming protected tags to use <div> replacers instead.
 *
 * @param stringHtml The HTML to virtualize.
 * @returns IHtmlVirtualizationHook
 */
export const useHtmlVirtualization = (stringHtml: string): IHtmlVirtualizationHook => {
	const virtualElementsRef = useRef<Element[]>([]);
	const originalElementsRef = useRef<Element[]>([]);
	const virtualElementIdsRef = useRef<string[]>([]);
	const [erroredElements, setErroredElements] = useState<{ elementType: string; error: string }[]>([]);
	const virtualHtmlRef = useRef<Document>(null);
	const originalHtmlRef = useRef<Document>(null);
	const previousHtmlString = useRef<string>(null);
	const nodeIframeUrl = useRef(null);

	/**
	 * Gets Miscellaneous data to use for virtualization purposes
	 */
	async function getMiscData() {
		await ServiceHub.articleDataAPI
			.start()
			.getMiscellaneous()
			.then((result) => {
				if (!Array.isArray(result)) return;

				nodeIframeUrl.current = result;
			})
			.catch((ex) => {
				// TODO: Handle error
			});
	}

	/**
	 * Gets a specific Miscellaneous key,
	 * Based on the data key value
	 *
	 * @param key
	 * @returns
	 */
	async function getMiscellaneousKey(key: string): Promise<{ DataKey: string; Value: string }> {
		if (!nodeIframeUrl.current) {
			await getMiscData();
		}

		const itemByKey = nodeIframeUrl.current.find((item: any) => item?.DataKey && item.DataKey === key);

		return itemByKey;
	}

	const onClearErrors = () => {
		setErroredElements([]);
	};

	/**
	 * Clears the Virtualization properties,
	 * for using when quitting/re-init.
	 */
	const onReset = () => {
		virtualHtmlRef.current = null;
		originalElementsRef.current = [];
		virtualElementsRef.current = [];
		virtualElementIdsRef.current = [];
		originalHtmlRef.current = null;

		onClearErrors();
	};

	/**
	 * For a given Document or Element,
	 * gets its content inner HTML.
	 *
	 * @param htmlDocument The Document or Element to get content from
	 * @returns string
	 */
	const getBodyContent = (htmlDocument: Document | Element): string => {
		const baseElement = htmlDocument instanceof Element ? htmlDocument : htmlDocument.documentElement;

		const bodiesElements = baseElement.getElementsByTagNameNS(_xhtmlNamespace, "body");
		const hasBody = bodiesElements.length > 0;

		return hasBody ? bodiesElements[0].innerHTML : baseElement.innerHTML;
	};

	/**
	 * Sets a body content for a given html document passed.
	 *
	 * @param htmlDocument The document having its content set.
	 * @param newContent The new content to set in the document.
	 */
	const setBodyContent = (htmlDocument: Document, newContent: string): void => {
		const bodiesElements = htmlDocument.documentElement.getElementsByTagNameNS(_xhtmlNamespace, "body");
		const hasBody = bodiesElements.length > 0;

		if (hasBody) {
			bodiesElements[0].innerHTML = newContent;
		} else {
			htmlDocument.documentElement.innerHTML = newContent;
		}
	};

	/**
	 * Sync-up the virtual HTML back to the high-fidelity,
	 * the one without placeholder tags.
	 *
	 * @param event The event of change.
	 * @param onComplete An onComplete callback to process additional steps.
	 */
	const onSyncVirtualToOriginal = (event, onComplete?: undefined | ((finalHtml: string) => void)): void => {
		let newContent = virtualHtmlRef.current.documentElement.cloneNode(true) as Element;

		// Pre-clears the errors list
		onClearErrors();

		// Create a map of virtualElement.outerHTML to originalElement.outerHTML
		const replacements = new Map<string, string>();
		virtualElementsRef.current.forEach((virtualElement, index) => {
			const originalElement = originalElementsRef.current.find(
				(item) => item.getAttribute("data-id") === virtualElement.getAttribute("data-id")
			);

			if (originalElement) {
				// Removes the attribute, since it was only for tracking purposes
				originalElement.removeAttribute("data-id");
				replacements.set(virtualElement.outerHTML, originalElement.outerHTML);
			}
		});

		if (replacements.size > 0) {
			// Replace all occurrences in one go
			newContent.innerHTML = newContent.innerHTML.replace(
				new RegExp(Array.from(replacements.keys()).join("|"), "g"),
				(matched) => replacements.get(matched)
			);
		}

		// Gets the new HTML content, which is the one listened by source editors
		const finalContent = getBodyContent(newContent);
		setBodyContent(originalHtmlRef.current, finalContent);

		// If an onComplete callback was provided, consume it
		if (typeof onComplete === "function") onComplete(finalContent);
	};

	/**
	 * Changes a virtual HTML, which includes protected tags
	 * controlled by placeholder state trackers.
	 *
	 * @param newContent The new content being set.
	 */
	const onChangeVirtualHtml = (newContent: string): void => {
		if (!virtualHtmlRef.current || virtualHtmlRef.current === null) {
			if (!newContent || newContent === "") return;

			onPrepareState(newContent);
		}

		setBodyContent(virtualHtmlRef.current, newContent);
	};

	/**
	 * The memoized version of the Virtual inner HTML.
	 */
	const virtualHtmlMemo = useMemo(
		() => (virtualHtmlRef.current ? getBodyContent(virtualHtmlRef.current) : ""),
		[virtualHtmlRef.current]
	);

	/**
	 * Validates an <iframe>'s tag src attribute value.
	 * TODO: Split this for reusability, when required.
	 *
	 * @param validatingElement The validating element
	 * @param iframeRegExUrl The URL to check present inside the attribute value
	 */
	function validateIframeSrc(validatingElement: Element, iframeRegExUrl: string): boolean {
		if (!validatingElement) return true;

		// Pre-clearing errors, before new validation
		onClearErrors();
		const iframeSrc = validatingElement.getAttribute("src");
		const hasValidSrc = iframeSrc.startsWith(iframeRegExUrl);

		if (!hasValidSrc) {
			const errorMessage =
				`Body: One or more <iframe> elements were detected with an ` +
				`Invalid src (URL). Allowed URLs should start with ${iframeRegExUrl}.*`;
			ServiceHub.message.error(errorMessage);
			setErroredElements([
				...erroredElements,
				{
					elementType: "iframe",
					error: errorMessage
				}
			]);
		}

		return hasValidSrc;
	}

	/**
	 * Prepares the state of the Virtualizing hook.
	 * Should be called for init/reset.
	 */
	const onPrepareState = async (htmlData: string) => {
		const protectedTags = ["iframe", "video", "embed"];
		// 	- Dangerous Tags Detector
		//   0 - Create HTML virtualization function
		//   1 - Identify if content contains (embed, iframe, video, etc...) virtualizing HTML.
		//   1.1 - Validate whether specific type of content is valid: e.g. iframe's SRC attributes
		//   2 - Map identified tags to generated ids.
		//   3 - Create placeholders supported by RTE, assigning the same ids generated above.
		//   4 - Store the RTE body version wrapping this identified tags using id to keep in 'Preview' mode.
		//   5 - When switch back to 'Source' show original version looking however checking old ids.
		//   6 - Sets the main virtualized state at the end.
		previousHtmlString.current = htmlData;
		originalHtmlRef.current = new DOMParser().parseFromString(htmlData, "text/html");
		virtualHtmlRef.current = originalHtmlRef.current.cloneNode(true) as Document;
		let virtualHtmlBody = getBodyContent(virtualHtmlRef.current);
		const iframeRegExUrl = (await getMiscellaneousKey(MiscellaneousKeys.NodeIframeUrl))?.Value ?? null;

		onClearErrors();

		protectedTags.forEach((protectedTag) => {
			const elementsToVirtualize = virtualHtmlRef.current.getElementsByTagNameNS(_xhtmlNamespace, protectedTag);

			if (elementsToVirtualize.length) {
				for (const dangElement of elementsToVirtualize) {
					// let hasValidSrc = false;
					const tagName = dangElement.tagName.toLowerCase();

					// If the tag uses src, that should be pre-verified
					// as part of the allowed integrations
					// And the src value is not allowed, will skip its render
					if (tagName === "iframe" && !validateIframeSrc(dangElement, iframeRegExUrl)) {
						continue;
					}

					// Generates a virtual item ID for replacing a sensitive tag
					const virtualItemId = `virtual-${protectedTag}-${new Date().getTime()}`;
					virtualElementIdsRef.current.push(virtualItemId);

					let virtualItem = document.createElement("div");

					// Assigns the item-item to all of the elements virtualized,
					// As well as keep the virtualized copy of the elements
					virtualItem.innerHTML = `&lt;${protectedTag} /&gt;`;
					virtualItem.setAttribute("data-id", `${virtualItemId}`);
					virtualItem.setAttribute("class", "rte-tag-placeholder");

					// Replaces the item in the virtual string HTML
					virtualHtmlBody = virtualHtmlBody.replace(dangElement.outerHTML, virtualItem.outerHTML);

					// Stores the virtual element for future use
					virtualElementsRef.current.push(virtualItem);

					dangElement.setAttribute("data-id", `${virtualItemId}`);
					// Store the original elements reference
					originalElementsRef.current.push(dangElement);
				}
			}
		});

		// Finalizes the item in the full string HTML
		onChangeVirtualHtml(virtualHtmlBody);
	};

	/**
	 * Pre-initializes the miscellaneous data.
	 */
	useEffect(() => {
		getMiscData();
	}, []);

	// Initial/Differential state load controller effect
	useEffect(() => {
		if (stringHtml !== previousHtmlString.current) {
			onPrepareState(stringHtml ?? "");
		}
	}, [onPrepareState, stringHtml, previousHtmlString.current]);

	return {
		virtualHtml: virtualHtmlMemo,
		originalHtml: stringHtml,
		virtualElementIds: virtualElementIdsRef.current,
		virtualElements: virtualElementsRef.current,
		originalElements: originalElementsRef.current,
		erroredElements,
		onChange: onChangeVirtualHtml,
		onSyncOriginal: onSyncVirtualToOriginal,
		onClearAll: onReset,
		onClearErrors
	};
};
