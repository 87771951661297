import { NodeType } from "../../contracts/models/strikeEnums";
import {
	resetDashboardButtonTitle,
	resetSearchTopics,
	resetSpaceSelectedValue,
	resetTopicsValues,
	setDashboardButtonTitle,
	setDashboardNavigation,
	setIsDashboardLoading,
	setIsFilterVisible
} from "../../redux/reducers/filter";
import store from "../../redux/store";

/**
 * Strike Spaces (nodes) Data management API. Used by through ServiceHub.
 */
export class FilterAPIService {
	/**
	 * [Redux] Sets the currently selected Space Id.
	 *
	 * @param id The new ID to set. Can be nulled to unselect data.
	 */
	setSelectedDashBoardButton(text: string): void {
		store.dispatch(setDashboardButtonTitle(text));
	}

	/**
	 * [Redux] Reset Dashboard button test
	 *
	 */
	resetDashBoardButtonTitle(): void {
		store.dispatch(resetDashboardButtonTitle());
	}

	/**
	 * Resets topics input values and clean the redux saved topics
	 */
	resetTopicsFilters(): void {
		store.dispatch(resetTopicsValues());
		store.dispatch(resetSearchTopics());
		store.dispatch(resetSpaceSelectedValue());
	}

	/**
	 * Reset only topics values but not space item selected.
	 */
	resetOnlyTopicsValues(): void {
		store.dispatch(resetTopicsValues());
		store.dispatch(resetSearchTopics());
	}

	/**
	 * Flag to manage loading Dashboards events
	 */
	setDashboardLoading(): void {
		store.dispatch(setIsDashboardLoading(true));
	}
	/**
	 * Set filter visible on certain routes
	 */
	setFilterVisible(value: boolean): void {
		store.dispatch(setIsFilterVisible(value));
	}

	/**
	 * [Redux] Sets Dashboard Navigation Type to use querying nodes by type
	 * @param dashboardType
	 */
	setDashboardNavigation(dashboardType: NodeType | undefined): void {
		store.dispatch(setDashboardNavigation(dashboardType));
	}
}
