import React from "react";

import { useFollowers } from "../../../hooks";
import { FollowType } from "../../../enums";
import { UserListBox } from "../user";
import { ServiceHub } from "../../../service";

/**
 * Controls the properties of the FollowerSection component.
 */
export interface IFollowerSectionProps {
	nodeId: number | string;
	type: FollowType;
	title: string;
	primaryActionTitle?: undefined | string;
	customNodeTypeName?: undefined | string;
}

/**
 * Reusable Node/Section/Topic follow section.
 * Provides a Follow/Unfollow button,
 * as well as a list of the current followers for a given node (based on its ID).
 *
 * @param props The configuration props.
 * @returns React.FC<IFollowerSectionProps>
 */
export const FollowerSection: React.FC<IFollowerSectionProps> = (props) => {
	// Prepares to consume useFollowers data
	const followersData = useFollowers(props.nodeId, props.type);
	const isUserFollowing = followersData.currentUserFollowing;
	const memoFollowers = followersData.followers?.map((follower) => follower.UserUPN);
	// const memoFollowers = useMemo(
	// 	() =>
	// 		followersData.querying || !followersData.queried
	// 			? []
	// 			: followersData.followers?.map((follower) => follower.UserUPN),
	// 	[followersData.querying, followersData.queried, followersData.followers]
	// );

	/**
	 * Handles the Follow/Unfollow states of this follower section.
	 */
	async function handleFollow() {
		await ServiceHub.nodeAPI.follow(props.nodeId, props.type, !isUserFollowing);
		// followersData.refresh();
	}

	return (
		<>
			{
				<UserListBox
					title={props.title}
					usersUpn={memoFollowers}
					primaryActionIcon={isUserFollowing ? "UserFollowed" : "FollowUser"}
					primaryActionTitle={props?.primaryActionTitle ?? isUserFollowing ? "Following" : "Follow"}
					primaryActionClick={handleFollow}
					primaryActionVariation={isUserFollowing ? "secondary" : "primary"}
					baselineText={
						followersData.querying ? (
							"Loading"
						) : !followersData.queried || followersData.followers.length === 0 ? (
							""
						) : followersData.followers.length > 1 ? (
							<>
								<b>{followersData.followers.length}</b> People are following this{" "}
								{props?.customNodeTypeName ?? props.type}
							</>
						) : (
							<>
								<b>1</b> Person is following this {props?.customNodeTypeName ?? props.type}
							</>
						)
					}
					hideEmptyRecordsMessage
				/>
				// TODO: Add shimmer
			}
		</>
	);
};
