import { useSelector } from "react-redux";
import { ISpaceFormData, ISpaceItem } from "../../contracts";
import { spacesSelectors } from "../../redux/selectors/spaces";
import { IGroup } from "@fluentui/react";
import { ITreeItem } from "@coherence-design-system/tree-view";
import { spaceFormSelectors } from "../../redux/selectors/spaceForm";
import { useMemo } from "react";

/**
 * Contract for the Spaces Form hook.
 */
interface ISpaceFormHook {
	spaces: ISpaceItem[];
	spaceItems: ISpaceItem[];
	spaceGroups: IGroup[];
	isOpenDeleteSpaceConfirmation: boolean;
	SpaceToDelete: string;
	SpaceIdToDelete: string;
	parentSpaces: ITreeItem[];
	getSelectedId: string;
	getCurrentParentSpace: string;
	spaceFormData: ISpaceFormData;
	isNewSpace: boolean;
	validation: ISpaceFormValidation;
	canSubmit: boolean;
	isFormDataLoaded: boolean;
	isFormDataLoading: boolean;
	isManageHeaderLoaded: boolean;
	isManageHeaderLoading: boolean;
	isManageListLoaded: boolean;
	isManageListLoading: boolean;
	coverImageFile: string;
}

/**
 * Space Form Validation properties shape
 */
export interface ISpaceFormValidation {
	[key: string]: boolean;
	id?: boolean;
	parentId: boolean;
	name: boolean;
	description?: boolean;
}

/**
 * Hook to handle and processing Space Form data to
 * use in Manage Spaces.
 * @returns ISpaceFormHook
 */
export const useSpaceForm = (): ISpaceFormHook => {
	const spaces = useSelector(spacesSelectors.getSpacesList);
	const spaceItems = useSelector(spacesSelectors.getSpaceManagerItemsList);
	const spaceGroups = useSelector(spacesSelectors.getSpaceManagerGroupsList);
	const isOpenDeleteSpaceConfirmation = useSelector(spacesSelectors.getIsOpenDeleteConfirmation);
	const SpaceToDelete = useSelector(spacesSelectors.getSpaceToDelete);
	const SpaceIdToDelete = useSelector(spacesSelectors.getSpaceIdToDelete);
	const parentSpaces = useSelector(spacesSelectors.getParentSpaces);
	const getSelectedId = useSelector(spacesSelectors.getSelectedId);
	const getCurrentParentSpace = useSelector(spacesSelectors.getCurrentParentSpace);
	const spaceFormData = useSelector(spaceFormSelectors.getData);
	const isSpaceFormLoading = useSelector(spaceFormSelectors.getIsLoading);
	const getFormSelectedParentId = useSelector(spaceFormSelectors.getFormSelectedItem);
	const isFormDataLoaded = useSelector(spaceFormSelectors.getIsDataLoaded);
	const isFormDataLoading = useSelector(spaceFormSelectors.getIsDataLoading);
	const isManageHeaderLoaded: boolean = useSelector(spaceFormSelectors.getIsManageHeaderLoaded);
	const isManageHeaderLoading: boolean = useSelector(spaceFormSelectors.getIsManageHeaderLoading);
	const isManageListLoaded: boolean = useSelector(spaceFormSelectors.getIsManageListLoaded);
	const isManageListLoading: boolean = useSelector(spaceFormSelectors.getIsManageListLoading);
	const coverImageFile = useSelector(spaceFormSelectors.getCoverImageFile);

	const validation = useMemo<ISpaceFormValidation>(() => {
		return {
			parentId: spaceFormData.parentId !== null && spaceFormData.parentId !== 0 && spaceFormData.parentId !== "",
			name: spaceFormData.name !== ""
		};
	}, [spaceFormData]);

	/**
	 * Differentiates between New and Edit Space
	 */
	const isNewSpace = useMemo(() => {
		return !spaceFormData?.id;
	}, [spaceFormData]);

	// useEffect(() => {

	// }, [isLoaded, isLoading]);

	const canSubmit = useMemo(() => validation.name && validation.parentId, [validation]);

	return {
		spaces,
		spaceItems,
		spaceGroups,
		isOpenDeleteSpaceConfirmation,
		SpaceToDelete,
		SpaceIdToDelete,
		parentSpaces,
		getSelectedId,
		getCurrentParentSpace,
		spaceFormData,
		isNewSpace,
		getFormSelectedParentId,
		validation,
		canSubmit,
		isSpaceFormLoading,
		isFormDataLoading,
		isFormDataLoaded,
		isManageHeaderLoaded,
		isManageHeaderLoading,
		isManageListLoaded,
		isManageListLoading,
		coverImageFile
	} as ISpaceFormHook;
};
