import React, { useMemo } from "react";
import { ICommandBarItemProps, IContextualMenuItem } from "@fluentui/react";
import { ContextMenu } from "../../contextMenu";
import { NodeActionTypes, NodeType } from "../../../../contracts/models/strikeEnums";
import { ServiceHub } from "../../../../service";
import { useSelector } from "react-redux";
import { commentsSelectors } from "../../../../redux/selectors/comments";
import { useAuthUser } from "../../../../hooks";

/**
 * Node Activity Context menu builder props.
 */
export interface INodeActivityContextMenuProps {
	nodeId: number;
	nodeType: string | NodeType;
	disabled?: undefined | boolean;
}

/**
 * Standard Node Activity context menu builder.
 *
 * @param props INodeActivityContextMenuProps
 * @returns React.FC<INodeActivityContextMenuProps>
 */
export const NodeActivityContextMenu: React.FC<INodeActivityContextMenuProps> = (props) => {
	const nodeTypeName = useMemo(() => {
		let _nodeName = props.nodeType;

		switch (props?.nodeType) {
			case NodeActionTypes.Answer: {
				_nodeName = "Answer";
				break;
			}
			case NodeActionTypes.Comment: {
				_nodeName = "Comment";
				break;
			}
			case NodeActionTypes.CommentToAnswer:
			case NodeActionTypes.AnswerToComment:
			default: {
				_nodeName = "Reply";
				break;
			}
		}

		return _nodeName;
	}, [props.nodeType]);
	const nodeById = useSelector((state) => commentsSelectors.getEditingItemById(state, props.nodeId));
	const authUser = useAuthUser();
	const isOwner = useMemo(() => {
		return authUser && authUser.userPrincipalName === nodeById.CommentByEmail;
	}, [authUser, nodeById]);

	const strings = {
		button: {
			icon: {
				more: {
					name: "More"
				},
				edit: {
					name: "Edit"
				}
			},
			more: {
				key: "moreActions",
				ariaLabel: `More ${nodeTypeName} Actions`
			},
			edit: {
				key: `Edit${nodeTypeName}`,
				ariaLabel: `Edit this ${nodeTypeName}`
			}
		},
		contextMenu: {
			ariaLabel: `${nodeTypeName} actions`
		}
	};

	const items: ICommandBarItemProps[] = [
		{
			key: strings.button.more.key,
			cacheKey: strings.button.more.key,
			ariaLabel: strings.button.more.ariaLabel,
			disabled: props?.disabled ?? false,
			text: "More",
			subMenuProps: {
				items: [
					{
						key: strings.button.edit.key,
						ariaLabel: strings.button.edit.ariaLabel,
						text: `Edit ${nodeTypeName}`,
						iconProps: {
							iconName: strings.button.icon.edit.name
						},
						onClick: (
							ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
							item?: IContextualMenuItem
						) => {
							const svc = ServiceHub.commentAPI.start();

							const editingItem = svc.getEditingItemById(props.nodeId);

							svc.setEditingIds([props.nodeId]);
							svc.setFormText(editingItem.Title);
						}
					}
				]
			}
		}
	];

	return isOwner ? <ContextMenu items={items} ariaLabel={strings.contextMenu.ariaLabel} /> : null;
};
