import * as React from "react";
import { useConst, useBoolean } from "@fluentui/react-hooks";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Callout } from "@fluentui/react/lib/Callout";
import { IContextualMenuProps, IContextualMenuItem } from "@fluentui/react/lib/ContextualMenu";

/**
 * Contract for the use of Button Dropdown as a group of actions.
 */
interface IButtonDropdownProps {
	callToActionText: string;
	items: IContextualMenuItem[];
	fullWidth?: undefined | boolean;
	className?: undefined | string;
}

/**
 * Renders an inline Button with child dropdown of possible actions.
 * There will be a default callToActionText defined at the root of the button.
 *
 * @param props IButtonDropdownProps
 * @returns React.FC<IButtonDropdownProps>
 */
export const ButtonDropdown: React.FC<IButtonDropdownProps> = (props) => {
	const [showCallout, { setFalse: onHideCallout }] = useBoolean(false);

	const menuProps: IContextualMenuProps = React.useMemo(
		() => ({
			shouldFocusOnMount: true,
			items: props.items
		}),
		[props.items]
	);

	return (
		<div>
			<DefaultButton
				text={props?.callToActionText ? props.callToActionText : "Actions"}
				menuProps={menuProps}
				className={`dropdown-button ${props?.fullWidth ? "full-width" : ""} ${
					props?.className !== "" ? props.className : ""
				}`}
			/>
			{showCallout && (
				<Callout setInitialFocus onDismiss={onHideCallout}>
					<DefaultButton
						onClick={onHideCallout}
						text={props?.callToActionText ? props.callToActionText : "Actions"}
					/>
				</Callout>
			)}
		</div>
	);
};
