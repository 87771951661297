import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import { LayoutType } from "../../contracts";
import { QueryNodeModel, TABLE_PAGE_SIZE } from "../../contracts/models";
import { SpaceTags, SpaceTypes, TitleType } from "../../contracts/models/strikeEnums";
import { setLayout } from "../../redux/reducers/appManagement";
import { appManagementSelectors } from "../../redux/selectors/appManagement";
import { ServiceHub } from "../../service";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import PageHeader from "../Header/page.header";
import { Alert } from "../common/alert";
import { NodeSummary } from "./node.summary";
import { NodeSummaryGrid } from "./node.summary.grid";
import { LayoutSwitcher } from "./node.summary.switcher";
import { LightPagination } from "../pagination";
import { ApplicationRoutePaths } from "../router";
import { useDashboardTitle } from "../../hooks";
import { setNodeList } from "../../redux/reducers/filter";
import { useFilterSlice } from "../../hooks/filter/useFilterSlice";
import { FollowerSection } from "../common/follower";
import { FollowType } from "../../enums";
import { StickyNodes } from "../stickyNodes/stickyNodes";
import { SpaceDetailsBox } from "../common/node/space/space.details.box";
import { FilterButtons } from "../filter/filter.buttons";

/**
 * The Node List Page component.
 * Used to render a list of Nodes loaded from the Backend services.
 *
 * @returns React.FC
 */
export const NodesList: React.FC = () => {
	const params = useParams();

	// Ref constants
	const previousNodeId = useRef("");
	const previousSearchTopics = useRef("");

	// Local states
	const [isLoading, setIsLoading] = useState(true);
	const [localLayoutMode, setLocalLayoutMode] = useState(undefined);
	const [refreshPaginationKey, setRefreshPagination] = useState(1);
	const [searchTitle, setSearchTitle] = useState("");
	const [pageIndex, setPageIndex] = useState(1);
	const dashboardTitle = useDashboardTitle();
	const filterData = useFilterSlice();
	const isSpaceDetailsLoaded = filterData.isSpaceDetailsLoaded;
	const strikeNodes = filterData.nodeList;
	const searchTopic = filterData.searchTopic;
	const searchTitleValue = filterData.searchTitle;
	const searchTopics = filterData.searchTopics;
	const filterAPI = useRef(ServiceHub.filterAPI.start());
	const [isSpaceDashboard, setIsSpaceDashboard] = useState(false);

	// Specific hooks implementation
	const currentLayout = useSelector(appManagementSelectors.layout);

	const dispatch = useDispatch();
	const location = useLocation();
	const allSpaceTypes = Object.values(SpaceTypes) as string[];
	const allSpaceTags = Object.values(SpaceTags) as string[];

	// Memoized
	const isTopicsDashboard = useMemo(() => location?.pathname?.includes("topics"), [location]);
	const isSpacesDashboard = useMemo(() => location?.pathname?.includes("spaces"), [location]);

	const isCoursesTags = useMemo(
		() => allSpaceTags.some((courseTag) => location?.search?.includes(courseTag)),
		[location]
	);

	const isCoursesTypes = useMemo(() => allSpaceTypes.some((courseType) => params.id === courseType), [location]);
	const isOnlineCourse = params.id === SpaceTypes.OnlineCourses;
	const SerpentSessionsCourse = params.id === SpaceTypes.SerpentSessions;
	const SecurityShorts = params.id === SpaceTypes.SecurityShorts;

	const isGridMode = isOnlineCourse || (SerpentSessionsCourse && isCoursesTags) || (SecurityShorts && isCoursesTags);

	const nodeFollowType = useMemo(() => {
		return isTopicsDashboard ? FollowType.Topic : isSpacesDashboard ? FollowType.Space : "";
	}, [isTopicsDashboard, isSpacesDashboard]);
	const shouldDivideScreen = useMemo(() => {
		return params?.id && nodeFollowType !== null;
	}, [params?.id, nodeFollowType]);

	useEffect(() => {
		if (
			previousNodeId.current === "" ||
			params.id !== previousNodeId.current ||
			previousSearchTopics.current !== searchTopic
		) {
			// Node Id can be resolved by data selected from Spaces.
			// The idea is to use the filter-based data from Spaces Slice.
			const resolvedNodeId = !params.id ? "" : params.id;

			// Stores the lastly resolved NodeId and Search topics
			previousNodeId.current = resolvedNodeId;
			previousSearchTopics.current = searchTopic;

			let queryModel = getQueryNodeObject(Number(resolvedNodeId), 1);
			getNodes(queryModel);
			refreshPagination();
		}
	}, [currentLayout, searchTopic, previousSearchTopics, params.id, previousNodeId]);

	const refreshPagination = () => {
		setRefreshPagination(Math.random());
	};

	const setLayoutSwitch = (layoutType: LayoutType) => {
		dispatch(setLayout(layoutType));
	};

	function getQueryNodeObject(
		node_Id: number,
		queryPageIndex: number = pageIndex,
		querySearchTitle: string | undefined = searchTitle,
		querySearchTopics: string | undefined = searchTopics,
		querySearchUrlTopics: string | undefined = previousSearchTopics.current
	): QueryNodeModel {
		const isTopics = location?.pathname?.includes("topics") ?? false;
		const resolvedNodeId = isTopics || node_Id === 0 || isNaN(node_Id) ? 0 : node_Id;
		const resolvedTopicId = !isTopics || node_Id === 0 ? 0 : node_Id;

		return {
			NodeId: resolvedNodeId,
			TopicId: resolvedTopicId,
			PageIndex: queryPageIndex,
			PageLimit: TABLE_PAGE_SIZE,
			SearchTitle: querySearchTitle,
			SearchTopics: querySearchTopics,
			SearchUrlTopics: querySearchUrlTopics
		} as QueryNodeModel;
	}

	const getNodes = async (queryNodeModel: QueryNodeModel) => {
		let res = await NodeAPIServiceInstance.getAll(queryNodeModel);
		if (await res.nodes) {
			dispatch(setNodeList(res));
			setIsLoading(false);
		}

		// If no local layout mode was defined (by user interaction)
		if (!localLayoutMode) {
			if (isGridMode && currentLayout) {
				setLayoutSwitch(LayoutType.GRID);
			} else {
				setLayoutSwitch(LayoutType.NORMAL_LIST);
			}
		} else {
			if (!isCoursesTypes) {
				setLayoutSwitch(LayoutType.NORMAL_LIST);
			}
		}
	};

	const onPageChange = (pageIndex: number): void => {
		setPageIndex(pageIndex);
		let queryModel = getQueryNodeObject(Number(params.id), pageIndex, searchTitleValue, searchTopics, searchTopic);
		filterData.getNodesList(queryModel);
	};

	/**
	 * Manages the title on the dashboard, also controls the DashboardTitle when we search, and get no results.
	 * Also used for cleaning-up filtering state, once redirecting to the root => /
	 */
	useEffect(() => {
		if (location.pathname === ApplicationRoutePaths.root()) {
			ServiceHub.appManagementAPI.resetDashboardFilters();
			filterAPI.current.setDashboardNavigation(undefined);
		}
		if (location.pathname.includes(ApplicationRoutePaths.topicsDashboard())) {
			filterAPI.current.resetDashBoardButtonTitle();
		}
		if (location.pathname.includes(ApplicationRoutePaths.spacesDashboard())) {
			if (params.id) {
				filterData.setSpaceDetailsLoaded(false);
				filterData.setSpaceCoverImageLoaded(false);
				filterData.getSpaceIsolatedData(params.id);
			}
			setIsSpaceDashboard(true);
		} else {
			setIsSpaceDashboard(false);
		}
	}, [location]);

	return (
		<div className="row">
			<div className={shouldDivideScreen ? "col-md-9" : "col-md-12"}>
				<PageHeader
					Title={dashboardTitle}
					Type={TitleType.Dashboard}
					showActions
					divided={shouldDivideScreen}
				/>
				<StickyNodes />
				{isLoading ? null : <FilterButtons />}
				<Divider />
				{!isLoading && strikeNodes.nodes.length === 0 ? (
					<Alert text={`No Records Found.`} />
				) : (
					<div>
						{isGridMode ? (
							<div className="text-end mt-2 col-lg-2 offset-lg-10">
								<LayoutSwitcher layout={localLayoutMode} setLayout={setLocalLayoutMode} />
							</div>
						) : null}

						<div
							className={
								currentLayout === LayoutType.GRID
									? "node-list-summary-grid-container"
									: "row layout-list"
							}
						>
							{strikeNodes.nodes.map((node, nodeIndex) =>
								currentLayout === LayoutType.GRID ? (
									<NodeSummaryGrid key={nodeIndex} Index={nodeIndex} NodeInfo={node} />
								) : (
									<NodeSummary key={nodeIndex} Index={nodeIndex} NodeInfo={node} />
								)
							)}
						</div>
						<LightPagination
							key={refreshPaginationKey}
							totalPages={strikeNodes.totalRecordCount}
							onPageChange={onPageChange}
						/>
					</div>
				)}
			</div>
			{shouldDivideScreen && nodeFollowType ? (
				<div className="col-md-3">
					{isSpaceDetailsLoaded && isSpaceDashboard ? <SpaceDetailsBox /> : null}
					<FollowerSection
						title={`Follow this ${nodeFollowType}`}
						nodeId={params?.id}
						type={nodeFollowType}
					/>
				</div>
			) : null}
		</div>
	);
};
