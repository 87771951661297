import React, { useRef } from "react";
import { FormActions, FormContainer, FormField } from "../../common/form";
import { Form } from "semantic-ui-react";
import { DatePicker, ISearchBox, SearchBox } from "@fluentui/react";
import { ServiceHub } from "../../../service";
import { TreeViewDropdown } from "../../common/dropdown";
import { useEventForm } from "../../../hooks";
import { IconButton } from "../../common/button";

interface DropdownItem {
	id: string;
	title: string;
}

/**
 * Component to Search and Filter over Events sandbox
 * @returns React.FC
 */
export const EventSearchForm: React.FC = () => {
	const currentDate = new Date();
	const eventFormService = useRef(ServiceHub.eventForm.start());
	const [selectedEndDate, setSelectedEndDate] = React.useState<Date>(currentDate);
	const [selectedStartDate, setSelectedStartDate] = React.useState<Date>(currentDate);
	const { eventTypes, eventStatus, selectedEventSearchTypeId, selectedEventSearchStatusId, eventFilterSearchText } =
		useEventForm();

	const onSearch = () => {
		//clean form fields
	};

	const onClear = () => {
		//clean form fields
	};

	const onChangeEmailName = () => {
		// manage on change input values
	};

	const onSelectStartDate = React.useCallback((selectedStartDate: Date) => {
		setSelectedStartDate(selectedStartDate);
	}, []);

	const onSelectEndDate = React.useCallback((selectedEndDate: Date) => {
		setSelectedEndDate(selectedEndDate);
	}, []);

	/**
	 * Handle search by Event Name input
	 * @param event
	 * @param newValue
	 */
	const handleEventFilterSearchName = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
		eventFormService.current.setEventFilterSearchText(newValue);
	};

	/**
	 * Handling selected Type to Filter Event List
	 * @param item
	 */
	function handleEventFilterSearchType(item: DropdownItem) {
		eventFormService.current.setEventFilterSearchType(item.id);
		eventFormService.current.setEventFilterSearchTypeOption(item.title);
	}

	/**
	 * Handling selected Status to Filter Event List
	 * @param item
	 */
	function handleEventFilterSearchStatus(item: DropdownItem) {
		eventFormService.current.setEventFilterSearchStatusId(item.id);
		eventFormService.current.setEventFilterSearchStatus(item.title);
	}

	const handleClearSearchButton = () => {
		eventFormService.current.setEventFilterSearchText("");
		eventFormService.current.setEventFilterSearchType("");
		eventFormService.current.setEventFilterSearchTypeOption("");
		eventFormService.current.setEventFilterSearchStatus("");
		eventFormService.current.setEventFilterSearchStatusId("");
	};

	return (
		<>
			<Form onSubmit={onSearch}>
				<div className="event-search-form-container-bar">
					<div className="event-search-form-container">
						<div className="event-search-form-column">
							<FormField id="searchByEventName" label="Name" className="event-search-form-field">
								<SearchBox
									id="searchByEventName"
									placeholder="Search by Event Name"
									onChange={handleEventFilterSearchName}
									autoComplete="off"
									value={eventFilterSearchText}
								/>
							</FormField>
						</div>
						<div className="event-search-form-column">
							<FormField
								id="searchByEventType"
								label="Type"
								className="event-search-form-field event-search-form-field-type"
							>
								<TreeViewDropdown
									id="searchByEventType"
									options={eventTypes}
									onChange={handleEventFilterSearchType}
									placeholder={"Select Event Type"}
									selectedItem={selectedEventSearchTypeId}
								/>
							</FormField>
						</div>
						<div className="event-search-form-column">
							<FormField
								id="searchByEventStatus"
								label="Status"
								className="event-search-form-field event-search-form-field-status"
							>
								<TreeViewDropdown
									id="searchByEventType"
									options={eventStatus}
									onChange={handleEventFilterSearchStatus}
									placeholder={"Select Event Status"}
									selectedItem={selectedEventSearchStatusId}
								/>
							</FormField>
						</div>
						{/* <div className="event-search-form-column">
						<FormField id="Title" label="Title">
							<TextInput
								id="Title"
								placeholder={"Search by Event Name"}
								onChange={onChange}
								value={temp}
								required
								maxLength={200}
							/>
						</FormField>

						<FormField id="Venue" label="Venue">
							<TextInput
								id="Venue"
								placeholder={"Search by Venue or Location"}
								onChange={onChange}
								value={temp}
								required
								maxLength={200}
							/>
						</FormField>
					</div> */}
						{/* <div className="event-search-form-column">
						<FormField id="dateStart" label="Start Date">
							<DatePicker
								className="event-search-form-datepicker"
								id="dateStart"
								placeholder={`Filter by Event Start date`}
								value={selectedStartDate}
								onSelectDate={onSelectStartDate}
								ariaLabel={`Filter by Event Start date`}
							/>
						</FormField>

						<FormField id="dateEnd" label="End Date">
							<DatePicker
								className="event-search-form-datepicker"
								id="dateEnd"
								placeholder={`Filter By Event End date`}
								value={selectedEndDate}
								onSelectDate={onSelectEndDate}
								ariaLabel={`Filter By Event End date`}
							/>
						</FormField>
					</div> */}
						{/* <div className="event-search-form-column">
						<FormField id="PresentedBy" label="Presented By">
							<TextInput
								id="PresentedBy"
								placeholder={"Search by Presenter"}
								onChange={onChange}
								value={temp}
								required
								maxLength={200}
							/>
						</FormField>
					</div> */}
					</div>
					<div className="event-search-form-button">
						<IconButton
							iconName="Broom"
							className="filter-actions-clear-icon-button"
							onClick={handleClearSearchButton}
							ariaLabel={"Clear Filters"}
							title="Clear Filters"
						/>
					</div>
					{/* <div className="event-search-form-actions">
					<FormActions
						actions={[
							{
								type: "submit",
								text: "Search",
								disabled: false
							},
							{
								type: "button",
								variation: "secondary",
								className: "cancel",
								text: "Clear",
								onClick: onClear
							}
						]}
					/>
				</div> */}
				</div>
			</Form>
		</>
	);
};
