import React from "react";
import { TopicList } from "./topic.list";

/**
 * Topics Dashboard, accessible by the route: "/topics"
 */
const TopicDashborad = (): React.ReactElement => {
	return <TopicList />;
};

export default TopicDashborad;
