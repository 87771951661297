import React, { useEffect, useRef, useState } from "react";
import { DropdownItemProps, DropdownOnSearchChangeData, DropdownProps } from "semantic-ui-react";
import { IButtonProps } from "../button";
import { ServiceHub } from "../../../service";
import { FormActions, FormContainer, FormField } from "../form";
import { TextInput } from "../text";
import { Dropdown } from "../dropdown";
import { useShareContent } from "../../../hooks";
import { IMSGraphUserBasic } from "../../../contracts/models";
import { NodeType } from "../../../contracts/models/strikeEnums";
import { useParams } from "react-router-dom";
import { showToast } from "../../../lib/strikeLibrary";
import { getCurrentUrl } from "../../router";

/**
 * Controls the input of the Share Content
 * Form component.
 */
interface IShareContentFormProps {
	nodeType: NodeType;
	shareTitle: undefined | string;
	item?: undefined | any;
	onSubmit?: undefined | (() => void | Promise<void>);
	onDismiss?: undefined | (() => void | Promise<void>);
}

/**
 * Component string map:
 * Share Content Form.
 */
const strings = {
	fields: {
		shareUrl: {
			id: "shareUrl",
			label: "Share URL"
		},
		shareUser: {
			id: "shareUser",
			label: "Share With",
			placeholder: "Search user"
		}
	},
	buttons: {
		copy: {
			text: "Copy Share URL",
			name: "Copy",
			icon: "copy"
		},
		share: {
			text: "Share"
		},
		dismiss: {
			text: "Dismiss"
		}
	}
};

/**
 * The Share Content form,
 * Used to share content at any state of the application.
 *
 * @param props IShareContentFormProps
 * @returns React.FC<IShareContentFormProps>
 */
export const ShareContentForm: React.FC<IShareContentFormProps> = (props) => {
	const graphAPI = useRef(ServiceHub.graphAPI.start());
	const searchTimeout = useRef<NodeJS.Timeout>(null);
	const shareContent = useShareContent();
	const params = useParams();
	const nodeTypeName =
		props.nodeType === NodeType.Kbentry ? "Article" : props.nodeType === NodeType.Question ? "Question" : "Node";

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentSearch, setCurrentSearch] = useState<string>("");
	const [shareRecipientItems, setShareRecipientItems] = useState<null | DropdownItemProps[]>([]);
	const [shareRecipients, setShareRecipients] = useState<null | string[]>([]);
	const [shareRecipientAliases, setShareRecipientAliases] = useState<null | string[]>([]);
	const [userList, setUserList] = useState<DropdownItemProps[]>([]);

	const isSubmitDisabled = shareRecipientItems === null || shareRecipientItems.length === 0;
	const resolvedOptions: DropdownItemProps[] = [...shareRecipientItems, ...userList];

	/**
	 * Generates the URL by picking it
	 * from the window.location.href.
	 *
	 * @returns URL as string.
	 */
	const onGenerateUrl = (): string => {
		return getCurrentUrl();
	};

	/**
	 * Handles the Copy URL action from
	 * the Form, by adding it to the
	 * Clipboard as item.
	 *
	 * @param event The button event to detect and prevent default behaviors.
	 */
	const onCopyUrl = async (event): Promise<void> => {
		event.preventDefault();
		const generatedUrl = onGenerateUrl();

		await navigator.clipboard.writeText(generatedUrl);
	};

	/**
	 * Clears the Select user from the Field.
	 */
	const onClearField = (): void => {
		setShareRecipients([]);
		setShareRecipientAliases([]);
		setShareRecipientItems([]);
	};

	/**
	 * Handles the On Change event from the User to Share With.
	 *
	 * @param event The event happening
	 * @param data The instant DropdownProps state
	 */
	const onChangeUser = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps): void => {
		if (data.value !== null && Array.isArray(data.value) && data.value.length > 0) {
			let userPrincipalNames = [];
			let userAliases = [];
			let dropdownItems = [];

			data.value.forEach((item) => {
				const dropdownItem = data.options.find((option) => item.toString() === option.key);

				if (!dropdownItem) return;

				dropdownItems.push(dropdownItem);
				userPrincipalNames.push(dropdownItem.key);
				userAliases.push(
					dropdownItem.key.toString().includes("@")
						? dropdownItem.key.toString().split("@")[0]
						: dropdownItem.key.toString()
				);
			});

			setShareRecipients(userPrincipalNames);
			setShareRecipientItems(dropdownItems);
			setShareRecipientAliases(userAliases);
		} else {
			onClearField();
		}
	};

	/**
	 * Handles submitting the form.
	 * Reads also the props.onSubmit as a step.
	 */
	const handleSubmit = async () => {
		let responses: boolean[] = [];
		shareContent.onSubmitting();

		if (typeof props?.onSubmit === "function") {
			await props.onSubmit();
		}

		// When sharing an ID, specifically
		// Calls the main Share Content functionality
		if (params?.id) {
			responses = await Promise.all(
				shareRecipients.map(async (recipient, index) => {
					return await ServiceHub.nodeAPI
						.shareContent(
							parseInt(params?.id),
							recipient,
							shareRecipientAliases[index],
							shareRecipientItems[index].title
						)
						.then((result) => {
							if (!result) {
								shareContent.onError();

								return false;
							}

							return true;
						})
						.catch((ex) => {
							shareContent.onError();

							return false;
						});
				})
			);
		}

		if (responses.every((sharedItem) => sharedItem)) {
			showToast(`${nodeTypeName} shared successfully.`, "success");

			shareContent.onComplete();
		} else {
			ServiceHub.message.error(`Sharing the ${nodeTypeName} encountered 1 or more errors.`);
		}
	};

	/**
	 * Handles dismissing the form.
	 * Read the props.onDismiss, if provided.
	 */
	const handleDismiss = () => {
		if (typeof props?.onDismiss === "function") {
			props.onDismiss();
		}

		shareContent.onDismiss();
	};

	/**
	 * Handles the event of changing the Search text,
	 * When querying users from MS Graph.
	 *
	 * @param event The event happening.
	 * @param data The Search Change event state.
	 */
	const onSearchChange = (
		event: React.SyntheticEvent<HTMLElement, Event>,
		data: DropdownOnSearchChangeData
	): void => {
		const search = data.searchQuery;
		setCurrentSearch(search);
	};

	/**
	 * Prepares the MS Graph User data to be bound to the
	 * DropdownOptions.
	 *
	 * @param users The user collection to process.
	 * @returns The converted DropdownOptions.
	 */
	const onConvertGraphUsersToDropdownOptions = (users: IMSGraphUserBasic[]) => {
		if (undefined === users || users === null || !Array.isArray(users)) return;

		const dropdownOptions = users.map((item): DropdownItemProps => {
			const userNameUpn = `${item.displayName} <${item.userPrincipalName}>`;

			return {
				key: item.userPrincipalName,
				title: item.displayName,
				text: userNameUpn,
				"aria-label": `Share ${nodeTypeName} with ${item.displayName}`,
				value: item.userPrincipalName
			} as DropdownItemProps;
		});

		setUserList(dropdownOptions);
	};

	/**
	 * When Searching the users is required,
	 * This method handles the processing.
	 */
	const onSearch = async () => {
		setIsLoading(true);
		await graphAPI.current.searchUsers(currentSearch).then(onConvertGraphUsersToDropdownOptions);
		setIsLoading(false);
	};

	/**
	 * Object with Form Actions configuration reference.
	 */
	const actionButtons: {
		share: IButtonProps;
		dismiss: IButtonProps;
	} = {
		share: {
			text: strings.buttons.share.text,
			disabled: isSubmitDisabled,
			onClick: handleSubmit,
			loading: shareContent.isSubmitting
		},
		dismiss: {
			text: strings.buttons.dismiss.text,
			variation: "secondary",
			onClick: handleDismiss
		}
	};

	/**
	 * The prepared version of the Action Forms,
	 * To be used as part of the Form.
	 */
	const resolvedActionButtons = [actionButtons.share, actionButtons.dismiss].filter((btn) => btn !== null);

	/**
	 * Handles the debounced search
	 * on Users by the search criteria.
	 */
	useEffect(() => {
		async function delayedSearch() {
			// Forcing a clean-up before a new timeout, since
			// It generates debounce effect
			if (searchTimeout.current !== null) clearTimeout(searchTimeout.current);

			searchTimeout.current = setTimeout(() => {
				onSearch();
			}, 500);
		}

		// Pre-processes the query, if specified
		delayedSearch();
	}, [currentSearch]);

	return (
		<FormContainer onSubmit={handleSubmit} title={props?.shareTitle} hideShadow hideRequiredNotice>
			<FormField id={strings.fields.shareUrl.id} label={strings.fields.shareUrl.label}>
				<TextInput
					id={strings.fields.shareUrl.id}
					disabled
					action={{
						"aria-label": strings.buttons.copy.text,
						name: strings.buttons.copy.name,
						icon: strings.buttons.copy.icon,
						title: strings.buttons.copy.text,
						onClick: onCopyUrl
					}}
					value={onGenerateUrl()}
					tabIndex={0}
				/>
			</FormField>
			<FormField
				id={strings.fields.shareUser.id}
				label={strings.fields.shareUser.label}
				onClear={onClearField}
				value={shareRecipients}
			>
				<Dropdown
					fluid
					search
					selection
					multiSelect
					id={strings.fields.shareUser.id}
					selectedValue={shareRecipients}
					options={resolvedOptions}
					onChange={onChangeUser}
					onSearchChange={onSearchChange}
					loading={isLoading}
					ariaLabel={strings.fields.shareUser.label}
					placeholder={strings.fields.shareUser.placeholder}
					renderLabel={(item) => {
						return item.title;
					}}
					closeOnEscape
					closeOnBlur
					useEnhancements
				/>
			</FormField>
			<FormActions actions={resolvedActionButtons} />
		</FormContainer>
	);
};
