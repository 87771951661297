/**
 * Definition of selectors
 * for Nodes Management.
 */
export const nodesSelectors = {
	getList: (state) => {
		return state.nodes.list;
	},
	getUserPhoto: (state, userUpn) => {
		return state.nodes.photos?.[userUpn] ?? "";
	},
	getQuerying: (state, userUpn) => state.nodes.querying?.[userUpn] ?? false,
	getQueried: (state, userUpn) => state.nodes.queried?.[userUpn] ?? false,
	getNodeTopicsIds: (state): string[] => state.nodes.node.topicsIds,
	getNodeTopicsNames: (state): string[] => state.nodes.node.topicsNames,
	getIsOpenDeleteConfirmation: (state): boolean => state.nodes.ui.isOpenDeleteConfirmation,
	getIsOpenStickConfirmation: (state): boolean => state.nodes.ui.isOpenStickConfirmation,
	getIsOpenShareContent: (state): boolean => state.nodes.ui.isOpenShareContent,
	getIsSubmittingShareContent: (state): boolean => state.nodes.ui.isSubmittingShareContent,
	getIsActionConfirmed: (state): boolean => state.nodes.ui.isActionConfirmed,
	getNodeConfirmationTitle: (state): string => state.nodes.ui.deleteConfirmationModalTitle,
	getNodeConfirmationSubText: (state): string => state.nodes.ui.deleteConfirmationModalSubText,
	getNodeConfirmationButtonText: (state): string => state.nodes.ui.deleteConfirmationModalButtonText,
	getConfirmationModalAction: (state): null => state.nodes.ui.confirmationModalAction,
	getConfirmationStickyAction: (state): null => state.nodes.ui.confirmationStickyAction,
	getIsOpenActionPostMessage: (state): boolean => state.nodes.ui.isOpenActionPostMessage,
	getIsChangeSpacePanelOpen: (state): boolean => state.nodes.ui.isChangeSpacePanelOpen,
	getStickSpaceStatus: (state): boolean => state.nodes.ui.isNodeSpaceSticky,
	getStickSiteStatus: (state): boolean => state.nodes.ui.isNodeSiteSticky,
	getPrivateStatus: (state): boolean => state.nodes.ui.isNodePrivate,
	getIsOpenPrivateConfirmation: (state): boolean => state.nodes.ui.isOpenPrivateConfirmation,
	getDeletedStatus: (state): boolean => state.nodes.ui.isNodeDeleted
};
