import { ITreeItem } from "@coherence-design-system/tree-view";
import { IStrikeNodes } from "../../contracts/models";
import { NodeType } from "../../contracts/models/strikeEnums";

/**
 * Definition of selectors related to main Filter
 */
export const filterSelectors = {
	getSearchTopics: (state) => state.filter.data.searchTopics,
	getSearchTitle: (state) => state.filter.data.searchTitle,
	getToggleExpanderFilter: (state) => state.filter.ui.expanded,
	getFilterLoaded: (state): boolean => state.filter.ui.loaded,
	getFilterLoading: (state): boolean => state.filter.ui.loading,
	getPageIndex: (state): number => state.filter.data.pageIndex,
	getNodeList: (state): IStrikeNodes => state.filter.data.nodeList,
	getNodesLoading: (state): boolean => state.filter.ui.nodesLoading,
	getDashboardButtonTitle: (state): string => state.filter.ui.dashboardButtonTitle,
	getDashboardNavigation: (state): NodeType | undefined => state.filter.ui.dashboardNavigation,
	getTopicsResetKey: (state): number => state.filter.ui.topicsResetKey,
	getSpaceSelectedValue: (state): ITreeItem => state.filter.ui.spaceSelectedValue,
	getIsFilterVisible: (state): any => state.filter.ui.isFilterVisible,
	getIsDashboardLoading: (state): any => state.filter.ui.isDashboardLoading
};
