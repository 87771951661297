import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { appManagementSelectors } from "../../redux/selectors/appManagement";
import { setLayout } from "../../redux/reducers/appManagement";
import { LayoutType } from "../../contracts";
import "./node.summary.switcher.css";

/**
 * @component
 * @description Component to add functionality to switch between layout views in a Dashboard list
 * uses state management to trigger switch actions using 2 types:
 * LayoutType.GRID, LayoutType.NORMAL_LIST
 */
export const LayoutSwitcher: React.FC<{
	layout?: undefined | LayoutType;
	setLayout?: undefined | ((layout: LayoutType) => void);
}> = (props) => {
	const dispatch = useDispatch();
	const currentLayout = useSelector(appManagementSelectors.layout);
	const resolvedLayout = useMemo(() => {
		return props?.layout !== undefined ? props.layout : currentLayout;
	}, [props.layout, currentLayout]);
	// const [localControl, setLocalControl] = useState(LayoutType.NORMAL_LIST);

	const handleLayoutSwitch = (layoutType: LayoutType) => {
		dispatch(setLayout(layoutType));

		if (typeof props?.setLayout === "function") {
			props.setLayout(layoutType);
		}
	};

	return (
		<div>
			<button
				aria-label="Button to Swicth Layout Grid Mode"
				className={resolvedLayout === LayoutType.GRID ? "button-switcher active" : "button-switcher"}
				onClick={() => handleLayoutSwitch(LayoutType.GRID)}
				role="switch"
				aria-checked={resolvedLayout === LayoutType.GRID}
			>
				<Icon
					name="th"
					size="small"
					color="grey"
					className={resolvedLayout === "GRID" ? "icon-highlight" : "icon-normal"}
					role="picture"
				/>
			</button>
			<button
				aria-label="Button to Swicth Layout List Mode"
				className={resolvedLayout === LayoutType.NORMAL_LIST ? "button-switcher active" : "button-switcher"}
				onClick={() => handleLayoutSwitch(LayoutType.NORMAL_LIST)}
				role="switch"
				aria-checked={resolvedLayout === LayoutType.NORMAL_LIST}
			>
				<Icon name="list" size="small" color="grey" role="picture" />
			</button>
		</div>
	);
};

export default LayoutSwitcher;
