import React from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { Toggle } from "@fluentui/react/lib/Toggle";

import { useEventNotificationTemplates, useHtmlVirtualization } from "../../hooks";
import { DivEventHandler, InputTextEventHandler, RichTextInput, TextInput } from "../common/text";
import { FormActions, FormContainer, FormField } from "../common/form";
import { useEffect } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useSuperAdminAccessControl } from "../../hooks/auth/useSuperAdminAccessControl";
import { UnauthorizedPage } from "../common/page";
import { EventNotificationTemplateType } from "../../enums";
import { Divider } from "semantic-ui-react";
import { EventsShimmer } from "../common/shimmer/events/shimmer.events";

/**
 * Component to serves as wrapper for Event Settings component
 * @returns EvenSettings component
 */
const EventSettingsPage: React.FC = () => {
	const notificationTemplates = useEventNotificationTemplates();
	const virtualHtml = useHtmlVirtualization(notificationTemplates.form.emailContent);
	const { isSuperAdmin, requested, requesting } = useSuperAdminAccessControl();

	const onChangeActive = (activeType: string) => {
		// Before resetting form, keep "dirty" state back to the list for late saving
		notificationTemplates.storeListItem(notificationTemplates.form);

		notificationTemplates.resetForm();
		notificationTemplates.setFormSelectedTemplateType(activeType);
	};

	const onChangeEmailContentText = useCallback(
		(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();
			virtualHtml.onChange(typeof event === "string" ? event : event.currentTarget?.value);
			notificationTemplates.onChangeProp(
				"emailContent",
				typeof event === "string" ? event : event.currentTarget.value
			);
		},
		[notificationTemplates.onChangeProp, virtualHtml.onChange]
	);

	const onChangeEmailContentDiv = useCallback(
		(event: React.FocusEvent<HTMLElement>) => {
			notificationTemplates.onChangeProp(
				"emailContent",
				typeof event === "string"
					? event
					: virtualHtml.virtualHtml.replace(/<[^>]*>/g, "") === ""
					? ""
					: event.currentTarget.innerText
			);
		},
		[notificationTemplates.onChangeProp, virtualHtml]
	);

	const handleSubmit = async (event) => {
		event.preventDefault();
		await notificationTemplates.onSave();
		await notificationTemplates.resetList();
	};

	const selectItemByType = (typeName: string) => {
		if (!typeName || typeName === "") return;

		// Pre-selects the edit template type
		if (notificationTemplates.selectedType !== typeName) {
			notificationTemplates.setFormSelectedTemplateType(typeName);
		}

		if (notificationTemplates.list.length > 0) {
			const activeItem = notificationTemplates.list.find((item) => item.Type === typeName);
			// Item list is present, however, this entry hasn't been created yet
			if (!activeItem) {
				notificationTemplates.setForm({
					emailContent: "",
					eventId: 0,
					type: typeName,
					id: null,
					subject: "",
					isActive: true
				});

				return;
			}

			// Selects the item for edit, automatically
			notificationTemplates.setForm({
				emailContent: activeItem.EmailContent,
				eventId: activeItem.EventId,
				type: activeItem.Type,
				id: activeItem.Id,
				subject: activeItem.Subject,
				isActive: activeItem.IsActive
			});

			return;
		}

		// At this stage, the list hasn't been populated yet
		if (notificationTemplates.form.subject !== "" || notificationTemplates.form.emailContent !== "") {
			notificationTemplates.setForm({
				emailContent: "",
				eventId: 0,
				type: typeName,
				id: null,
				subject: "",
				isActive: true
			});
		}
	};

	// Tracking changes to the Loading State of the form
	useEffect(() => {
		if (!notificationTemplates.isLoaded) {
			// Setting the default (initial) Template type
			if (!notificationTemplates.selectedType || notificationTemplates.selectedType === "") {
				notificationTemplates.setFormSelectedTemplateType(EventNotificationTemplateType.MeetingInvite);
			}

			notificationTemplates.onLoad();
		}
	}, [notificationTemplates.selectedType, notificationTemplates.isLoaded]);

	// Initializing the Form, when no Type has been selected yet
	useEffect(() => {
		if (!notificationTemplates.selectedType) {
			selectItemByType(EventNotificationTemplateType.MeetingInvite);

			return;
		}

		if (
			notificationTemplates.list.length > 0 &&
			!notificationTemplates.form.id &&
			notificationTemplates.list.find((item) => item.Type === notificationTemplates.selectedType)
		) {
			selectItemByType(notificationTemplates.selectedType);
		}
	}, [notificationTemplates.selectedType, notificationTemplates.list, notificationTemplates.form.id]);

	// Clean-up on unmount the component
	useEffect(() => {
		notificationTemplates.resetAll();

		() => {
			notificationTemplates.resetAll();
		};
	}, []);

	const resolvedTemplateTitle = useMemo(() => {
		if (notificationTemplates.selectedType === EventNotificationTemplateType.MeetingInvite) {
			return "Meeting Invite Template";
		}
		if (notificationTemplates.selectedType === EventNotificationTemplateType.AttendWaitlist) {
			return "Attend Waitlist Template";
		}
		if (notificationTemplates.selectedType === EventNotificationTemplateType.AttendConfirm) {
			return "Attend Confirm Template";
		}
		if (notificationTemplates.selectedType === EventNotificationTemplateType.NotAttend) {
			return "Not Attend Template";
		}
	}, [notificationTemplates.selectedType]);

	/**
	 * Loading temp component to show shimmer while list its loading
	 * @returns
	 */
	const renderLoading = () => {
		return (
			<div className={`row`}>
				<div className="col-md-12 col-lg-6 col-xl-12" tabIndex={0} aria-label="Event Form Page">
					<div className="space-manager-container">
						<EventsShimmer size={2} rows={1} tabsColumns />
					</div>
				</div>
			</div>
		);
	};

	const getTypeHasChanged = useCallback(
		(templateType: EventNotificationTemplateType) => {
			return notificationTemplates.changedTypes.includes(templateType);
		},
		[notificationTemplates.changedTypes]
	);

	const getChangedItemStyle = useCallback(
		(templateType: EventNotificationTemplateType) => {
			return {
				fontWeight: `${!getTypeHasChanged(templateType) ? 500 : 600} !important`
			};
		},
		[getTypeHasChanged]
	);

	/**
	 * Shows unauthorized component
	 * @returns
	 */
	const renderUnauthorized = () => {
		return <UnauthorizedPage />;
	};

	if (!requested && (requesting || notificationTemplates.isLoading)) {
		return renderLoading();
	}

	if (isSuperAdmin)
		return (
			<div className="strike-node-wrapper">
				<div className="event-settings-event-name" tabIndex={0} aria-label={notificationTemplates.eventName}>
					<h2>{notificationTemplates.eventName}</h2>
				</div>
				<Divider />
				<Pivot
					aria-label="Event Email Management Settings"
					linkFormat="tabs"
					className="event-settings-tabs"
					onLinkClick={(item, event) => {
						if (notificationTemplates.selectedType !== item.props.id) {
							onChangeActive(item.props.id);
						}
					}}
				>
					<PivotItem
						headerText={`Meeting Invite${
							getTypeHasChanged(EventNotificationTemplateType.MeetingInvite) ? " ●" : ""
						}`}
						itemIcon="MailForward"
						id={EventNotificationTemplateType.MeetingInvite}
						style={getChangedItemStyle(EventNotificationTemplateType.MeetingInvite)}
					></PivotItem>
					<PivotItem
						headerText={`Attend Confirm${
							getTypeHasChanged(EventNotificationTemplateType.AttendConfirm) ? " ●" : ""
						}`}
						itemIcon="MailCheck"
						id={EventNotificationTemplateType.AttendConfirm}
						style={getChangedItemStyle(EventNotificationTemplateType.AttendConfirm)}
					></PivotItem>
					<PivotItem
						headerText={`Attend Waitlist${
							getTypeHasChanged(EventNotificationTemplateType.AttendWaitlist) ? " ●" : ""
						}`}
						itemIcon="MailSchedule"
						id={EventNotificationTemplateType.AttendWaitlist}
						style={getChangedItemStyle(EventNotificationTemplateType.AttendWaitlist)}
					></PivotItem>
					<PivotItem
						headerText={`Not Attend${
							getTypeHasChanged(EventNotificationTemplateType.NotAttend) ? " ●" : ""
						}`}
						itemIcon="MailUndelivered"
						id={EventNotificationTemplateType.NotAttend}
						style={getChangedItemStyle(EventNotificationTemplateType.NotAttend)}
					></PivotItem>
				</Pivot>
				<FormContainer hideShadow hideLateralPadding title={resolvedTemplateTitle} onSubmit={handleSubmit}>
					<FormField id={"subject"} required label="Subject">
						<TextInput
							id="subject"
							onChange={(event) => {
								notificationTemplates.onChangeProp("subject", event.currentTarget.value);
							}}
							value={notificationTemplates.form.subject}
						/>
					</FormField>
					<FormField id={"emailContent"} required>
						<div className="form-field-header">
							<label htmlFor="emailContent" aria-label="Template Content">
								Template Content{" "}
								<span className="field-required-mark" tabIndex={0} aria-label="Required" role="text">
									*
								</span>
							</label>
							<div className="button-group" aria-label="Mode Select" role="group">
								<button
									type="button"
									onClick={() => notificationTemplates.setFormContentSourceMode(false)}
									className={`${!notificationTemplates.bodySourceMode ? "selected" : ""}`}
									aria-label={`Action: Set render Preview Mode.${
										!notificationTemplates.bodySourceMode ? " Selected." : ""
									}`}
								>
									Preview
								</button>
								<button
									type="button"
									onClick={() => notificationTemplates.setFormContentSourceMode(true)}
									className={`${notificationTemplates.bodySourceMode ? "selected" : ""}`}
									aria-label={`Action: Set render HTML Source Mode.${
										notificationTemplates.bodySourceMode ? " Selected." : ""
									}`}
								>
									Source
								</button>
							</div>
						</div>
						<div className="form-control">
							<RichTextInput
								id="emailContent"
								placeholder={
									notificationTemplates.bodySourceMode
										? "Type to start your HTML formatted Mail"
										: "Type to start your Mail template"
								}
								onChange={
									notificationTemplates.bodySourceMode
										? (onChangeEmailContentDiv as DivEventHandler)
										: (onChangeEmailContentText as InputTextEventHandler)
								}
								value={notificationTemplates.form.emailContent}
								sourceMode={notificationTemplates.bodySourceMode}
								onBlur={(event) => {
									virtualHtml.onSyncOriginal(event.currentTarget?.innerHTML, (finalHtml: string) => {
										notificationTemplates.onChangeProp("emailContent", finalHtml);
									});
								}}
							/>
						</div>
					</FormField>
					<FormField id={"isActive"}>
						<Toggle
							id="isActive"
							label="Active"
							defaultChecked
							onText="On"
							offText="Off"
							checked={notificationTemplates.form.isActive}
							onChange={(event: React.MouseEvent<HTMLElement>, checked: boolean) => {
								notificationTemplates.onChangeProp("isActive", checked);
							}}
						/>
					</FormField>
					<FormActions
						actions={[
							{
								type: "submit",
								text: "Save Settings"
								// disabled:
								// 	notificationTemplates?.list.length === 0 &&
								// 	notificationTemplates.form.subject === "" &&
								// 	notificationTemplates.form.emailContent === ""
							},
							{
								type: "button",
								variation: "secondary",
								className: "cancel",
								text: "Discard",
								onClick: notificationTemplates.onDiscard
							}
						]}
					/>
				</FormContainer>
			</div>
		);
	if (!isSuperAdmin && notificationTemplates.isLoaded && !notificationTemplates.isLoading) {
		return renderUnauthorized();
	}
};

export default EventSettingsPage;
