import React from "react";
import { FormActions, FormContainer, FormField } from "../../form";
import { NodeFormFieldIds, NodeFormLabels, NodeFormPlaceholders } from "../node.form.strings";
import { TreeViewDropdown } from "../../dropdown";
import { useNodeActionsSlice, useNodeForm } from "../../../../hooks";
import { NodeType } from "../../../../contracts/models/strikeEnums";
import { useMemo } from "react";
import { useRef } from "react";
import { ServiceHub } from "../../../../service";
import { useCallback } from "react";
import { ApplicationRoutePaths } from "../../../router";
import { useState } from "react";

/**
 * Controls the props Node Change Space Form component
 */
interface INodeChangeSpaceFormProps {
	type: NodeType;
	nodeTitle: string;
}

/**
 * Shared component to handle space change over Question or Kbentry nodes.
 * @param props
 * @returns
 */
export const NodeChangeSpaceForm: React.FunctionComponent<INodeChangeSpaceFormProps> = (props) => {
	const { formData, parentSpaces, hasError, queryingSpaces, spaces, isFieldEnabled } = useNodeForm(props.type);
	const articleFormService = useRef(ServiceHub.articleForm.start());
	const articleDataAPI = useRef(ServiceHub.articleDataAPI.start());
	const nodeAdminAPI = useRef(ServiceHub.nodeAdminAPI.start());
	const NodeActions = useNodeActionsSlice();
	const dismissChangeSpacePanel = NodeActions.dismissChangeSpacePanel;
	const [isSpaceUpdated, setIsSpaceUpdated] = useState(false);

	/**
	 * Memo to handle space selection item
	 */
	const selectedSpaceItem = useMemo(() => {
		if (!formData.spaceId) return null;

		const foundArticle = articleFormService.current.getSpaceById(formData.spaceId);

		if (!foundArticle || foundArticle === null) return null;

		return {
			id: formData.spaceId.toString(),
			text: foundArticle.Title
		};
	}, [formData.spaceId, articleFormService.current, spaces]);

	/**
	 * Callback to set data in state via service
	 */
	const onSetStateProp = useCallback(
		function (prop: string, value: any) {
			articleFormService.current.setData({
				...formData,
				[prop]: value
			});
		},
		[articleFormService, formData]
	);

	/**
	 * Cleaning Space selector handler
	 */
	const onClearSelection = useCallback(
		(prop: string, clearValue: undefined | string | [] = "") => {
			return (event: React.MouseEvent<HTMLAnchorElement>) => {
				onSetStateProp(prop, clearValue);
			};
		},
		[onSetStateProp]
	);

	/**
	 * Handling 'Discard' button triggered from user
	 * @param event
	 */
	function onCancel(event: React.FormEvent<HTMLButtonElement>) {
		articleFormService.current.reset();
		articleFormService.current.resetData();
		dismissChangeSpacePanel();
	}

	/**
	 * handle behavior once space in node
	 * its succeesfully updated
	 */
	function handleUpdateSuccess() {
		setIsSpaceUpdated(true);
		setTimeout(() => {
			setIsSpaceUpdated(false);
			dismissChangeSpacePanel();
		}, 3000);
	}
	/**
	 * Handling formData after update Space from Panel,
	 * using NodeType to split API method(admin or not)
	 * to process change. Showing temp confirmation alert
	 * on node update success after closing the panel.
	 * @param event
	 */
	function submitChangeSpace(event: React.FormEvent<HTMLFormElement>) {
		if (event.preventDefault) event.preventDefault();
		articleFormService.current.setSubmitting(true);

		if (props.type == NodeType.Kbentry) {
			nodeAdminAPI.current
				.createArticle(formData)
				.then((result: any) => {
					if (result instanceof Error) {
						ServiceHub.message.error(result.message);
						throw result;
					}

					articleFormService.current.resetData();

					if (!result.Id) {
						ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.dashboard());
						return;
					}
					handleUpdateSuccess();
				})
				.finally(() => {
					articleFormService.current.setSubmitting(false);
				});
		} else {
			articleDataAPI.current
				.createQuestion(formData)
				.then((result: any) => {
					if (result instanceof Error) {
						ServiceHub.message.error(result.message);
						throw result;
					}

					articleFormService.current.resetData();

					if (!result.Id) {
						ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.dashboard());
						return;
					}

					handleUpdateSuccess();
				})
				.finally(() => {
					articleFormService.current.setSubmitting(false);
				});
		}
	}

	return (
		<div className="node-change-space-container">
			<FormContainer title={props.nodeTitle} onSubmit={submitChangeSpace} useModerationAlert={false}>
				<FormField
					id={NodeFormFieldIds.space}
					label={NodeFormLabels.space}
					onClear={onClearSelection("spaceId", "")}
					value={formData.spaceId}
					required
				>
					<TreeViewDropdown
						id={NodeFormFieldIds.space}
						options={parentSpaces}
						placeholder={NodeFormPlaceholders[props.type].space}
						onItemToggled={(item, isExpanded) => {
							articleFormService.current.setExpandedSpaceById(parseInt(item.id), isExpanded);
						}}
						selectedItem={selectedSpaceItem}
						disabled={queryingSpaces}
						error={formData.spaceId === "" && hasError}
					/>
				</FormField>
				<FormActions
					actions={[
						{
							type: "submit",
							text: NodeFormLabels.actions.updateSpace
						},
						{
							type: "button",
							variation: "secondary",
							className: "cancel",
							text: NodeFormLabels.actions.cancel,
							onClick: onCancel
						}
					]}
				/>
			</FormContainer>
			{isSpaceUpdated ? (
				<p className="alert alert-success" role="alert">
					Space Updated Succesfully!
				</p>
			) : null}
		</div>
	);
};
