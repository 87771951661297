import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";

import { ITreeItem, TreeView } from "@coherence-design-system/tree-view";

/**
 * Tree item definition
 */
export interface TreeItem extends ITreeItem {}

/**
 * Controls the input props of Strike's Dropdown.
 */
export interface ITreeViewDropdownProps {
	options: TreeItem[];
	onItemToggled?: (item: ITreeItem, isExpanded: boolean) => Promise<void> | void;
	ariaLabel?: undefined | string;
	ariaRequired?: undefined | boolean;
	title?: undefined | string;
	alt?: undefined | string;
	selectedItem?: undefined | { id?: undefined | string; text?: undefined | string };
	placeholder?: undefined | string;
	open?: undefined | boolean;
	setOpen?: undefined | ((open: boolean) => void);
	onClear?: undefined | (() => void);
	id?: undefined | string;
	disabled?: undefined | boolean;
	required?: undefined | boolean;
	inline?: undefined | boolean;
	search?: undefined | boolean;
	error?: undefined | boolean;
	noCloseOnSelect?: undefined | boolean;
	onChange?: undefined | ((clickedItem: ITreeItem) => void);
}

/**
 * Renders a Strike-standard Dropdown component.
 * Implements a Fluent UI 8 Dropdown within.
 *
 * @param props IDropdownProps
 * @returns React.FC<IDropdownProps>
 */
export const TreeViewDropdown: React.FC<ITreeViewDropdownProps> = (props) => {
	const divRef = useRef(null);
	const [listOpen, setListOpen] = useState(props?.open ?? false);
	const item = useMemo(
		() => (!props?.selectedItem?.id || props.selectedItem.id === "" ? null : props.selectedItem),
		[props?.selectedItem]
	);
	const isOpen = useMemo(() => {
		return undefined !== props?.open ? props.open : listOpen;
	}, [props?.open, listOpen]);

	const toggleOpen = useCallback(() => {
		if (props.disabled) return false;

		// Toggles the list to open/closed
		if (undefined !== props.open && typeof props?.setOpen === "function") {
			props.setOpen(!props?.open);
		} else {
			setListOpen(!listOpen);
		}
	}, [props.disabled, props.open, props.setOpen, listOpen]);

	/**
	 * Generates a function-wrapper to automatically close the select on click
	 */
	const createOnItemClick = useCallback(
		(item: TreeItem) => (ev: React.MouseEvent<HTMLElement, MouseEvent>, clickedItem: ITreeItem) => {
			const fnCopy = item.onClick;

			fnCopy(ev, clickedItem);

			if (props?.noCloseOnSelect) return;

			if (typeof props?.onChange === "function") props?.onChange(clickedItem);

			toggleOpen();
		},
		[props?.onChange, props?.noCloseOnSelect, toggleOpen]
	);

	/**
	 * Maps an item, and its children to a protected
	 * clicking function which wraps each item's call with a component-controlled
	 * item clicking function.
	 */
	const mapItemClick = useCallback(
		(item: TreeItem) => {
			let mappedChildren = [];

			if (item?.children?.length > 0) {
				mappedChildren = item.children.map(mapItemClick);
			}

			return { ...item, onClick: createOnItemClick(item), children: mappedChildren };
		},
		[createOnItemClick]
	);

	/**
	 * Reference to the props.options mapped to use the dropdown treeview.close function
	 */
	const closeEnabledOptions = useMemo(() => props?.options.map(mapItemClick) ?? [], [props.options, mapItemClick]);

	/**
	 * Handles the dropdown Selector opener/closer events.
	 *
	 * @param event The Keyboard/Mouse event
	 */
	const handleSelectClick = (event: React.FormEvent<HTMLDivElement>) => {
		if (props.disabled) return false;

		// Toggles the list to open, if not yet
		if (!isOpen) {
			toggleOpen();
		}
	};

	/**
	 * Manages the entering-field event.
	 * Opens or closes the popover at desired steps,
	 * be it Tab in, or Tab out.
	 *
	 * @param event The Keyboard/Mouse event
	 */
	const handleFocus = (event) => {
		// To capture only scenarios where it was not open yet,
		// And the shiftKey is not pressed (reverse command, for instance)
		if (event.nativeEvent.type === "focusout" && !event.shiftKey) return;

		handleSelectClick(event);
	};

	/**
	 * Handles the KeyDown events processed within the
	 * main TreeView component, the Input field.
	 *
	 * @param event The Keyboard/Mouse event
	 */
	const handleKeyDown = async (event) => {
		const openFieldKeys = ["Enter", "Space"];
		const closeFieldKeys = ["Escape", "Enter"];
		const clearFieldKeys = ["Backspace", "Delete"];

		// If the keys pressed were any of the field activator ones
		if (openFieldKeys.includes(event.key) && !isOpen && !props?.disabled) {
			handleSelectClick(event);
		}

		// Handling the backwards Tab field leave case-scenario
		if (event.shiftKey && event.key === "Tab" && isOpen && !props?.disabled) {
			toggleOpen();
			return;
		}

		// If the keys pressed were any of the field closing ones
		if (closeFieldKeys.includes(event.key) && isOpen && !props?.disabled) {
			toggleOpen();
		}

		// If the key activated is the Return or Delete, and onClear function was provided
		if (clearFieldKeys.includes(event.key) && !props.disabled) {
			if (typeof props?.onClear === "function") {
				props?.onClear();
			}
		}
	};

	/**
	 * Handles the KeyDown events processed within the
	 * opened TreeView popover.
	 *
	 * @param event The Keyboard/Mouse event
	 */
	const handleDropdownKeyDown = async (event) => {
		const closeKeys = ["Tab", "Escape"];
		const clearKeys = ["Backspace", "Delete"];

		if (props?.disabled) return;

		if (!isOpen) return;

		if (closeKeys.includes(event.key)) {
			toggleOpen();
		}

		if (clearKeys.includes(event.key) && typeof props.onClear === "function") {
			props?.onClear();
			toggleOpen();
		}
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (divRef.current && !divRef.current.contains(event.target)) {
				toggleOpen();
				event.stopPropagation();
			}
		}

		if (isOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isOpen, divRef.current]);

	return (
		<div
			className={`tree-view-dropdown ${props?.inline ? "inline" : ""} ${props?.search ? "search" : ""}`}
			id={props.id ?? undefined}
		>
			<div
				className={`tree-view-dropdown-selected-item form-control-type-with-icon ${
					props?.disabled ? "disabled" : ""
				} ${props?.error ? "error" : ""}`}
				// role="select"
				onClick={handleSelectClick}
				onFocus={handleFocus}
				onKeyDown={handleKeyDown}
				tabIndex={0}
				title={props?.title ?? undefined}
				aria-label={props?.title ?? undefined}
				role="combobox"
				aria-required={props?.ariaRequired ?? undefined}
				aria-expanded={isOpen}
			>
				<div className="tree-view-dropdown-selected-item-text">
					<input
						required={props?.required ?? undefined}
						readOnly
						placeholder={props?.placeholder}
						value={item?.text ?? ""}
						alt={props?.alt ?? undefined}
					/>
				</div>
				<div className="tree-view-dropdown-selected-item-icon">
					<Icon name={`caret ${isOpen ? "up" : "down"}`} />
				</div>
			</div>
			{!props?.disabled && isOpen ? (
				<div className="tree-view-dropdown-options-overlay">
					<div
						ref={divRef}
						className="tree-view-dropdown-options-container"
						tabIndex={1}
						onKeyDown={handleDropdownKeyDown}
					>
						<TreeView
							aria-label={props.ariaLabel}
							data={closeEnabledOptions}
							onItemToggled={props.onItemToggled}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
};
