import React, { useEffect, useMemo, useState } from "react";
import { Icon } from "semantic-ui-react";
import { getQueryUserActivityObject, validateEmail } from "../../../../lib/strikeLibrary";
import { useAuthUser } from "../../../../hooks";
import { NodeActionTypes, NodeType } from "../../../../contracts/models/strikeEnums";
import { NodeAPIServiceInstance } from "../../../../service/NodeAPI";
import { UserActivityModel } from "../../../../contracts/models";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../../redux/selectors/auth";
import { NodeActivityContextMenu } from "./nodeActivity.context.menu";
/**
 * Interface for node activity actions
 */
export interface INodeActivityActionsProps {
	handleReplyExpand: (itemId: number) => void;
	id: number;
	likesNumber: number;
	likeByUsers: string;
	nodeType: string | NodeType;
	isEditing?: undefined | boolean;
}

/**
 * Component displaying a set of actions for a specific comment
 * Likes, Reply and More link menu its expected
 * @param props
 * @returns
 */
export const NodeActivityCommentActions: React.FC<INodeActivityActionsProps> = (props) => {
	const { userPrincipalName } = useAuthUser();
	const ipAddress = "";
	const [likeNumber, setLikeNumber] = useState(props.likesNumber);

	const likesCommentArray = useMemo(
		() =>
			!props?.likeByUsers || props?.likeByUsers === ""
				? []
				: props?.likeByUsers.includes(",")
				? props?.likeByUsers?.split(",").map((item) => parseInt(item))
				: [parseInt(props?.likeByUsers)],
		[props.likeByUsers]
	);

	const authoriableUserId = useSelector(authSelectors.getAuthoriableId);
	const [isCommentAlreadyLiked, setIsCommentAlreadyLiked] = useState(false);

	const [likeTriggered, setLikeTriggered] = useState(false);
	const [voteDown, setVoteDown] = useState(false);

	/**
	 * Handle comment like event activity and mocking like values on the fly.
	 * @param userActivityModel
	 */
	const logCommentLikeEventActivity = async (userActivityModel: UserActivityModel) => {
		let res = await NodeAPIServiceInstance.logUserActivity(userActivityModel);
		if (await res) {
			if (res) {
				if (!voteDown) {
					setLikeNumber(likeNumber + 1);
					likesCommentArray.push(authoriableUserId);
				} else if (voteDown) {
					if (likeNumber > 0) {
						setLikeNumber(likeNumber - 1);
					}
					const index = likesCommentArray.indexOf(authoriableUserId);
					if (index !== -1) {
						likesCommentArray.splice(index, 1);
					}
				}
			}
		}
	};

	/**
	 * Handle comment Like event action triggered by 'Like' button
	 */
	const handleCommentLike = () => {
		setLikeTriggered(true);
		const actionType = voteDown ? NodeActionTypes.VoteDown : NodeActionTypes.VoteUp;

		if (validateEmail(userPrincipalName)) {
			let queryCommentLikeActivity = getQueryUserActivityObject(
				actionType,
				ipAddress,
				props.id.toString(),
				userPrincipalName
			);
			logCommentLikeEventActivity(queryCommentLikeActivity);
		}
		setVoteDown(!voteDown);
	};

	/**
	 * Monitor if the authId for a user its present in the LikeByUsers list
	 * If there is match means user already liked it the node and action should be disabled.
	 */
	const monitorLikeState = () => {
		if (!likeTriggered) {
			if (likesCommentArray.length === 0 || !authoriableUserId || authoriableUserId === 0) {
				setIsCommentAlreadyLiked(false);
				return;
			}
			setIsCommentAlreadyLiked(likesCommentArray.includes(parseInt(authoriableUserId)));
		}
		// if like triggered button should be enabled till component unmount
		else {
			setIsCommentAlreadyLiked(false);
		}
	};

	const likeCountAriaLabel = `${likeNumber === 0 ? "No" : likeNumber} like${
		likeNumber === 0 || likeNumber > 1 ? "s" : ""
	}`;

	useEffect(() => {
		monitorLikeState();
	}, [monitorLikeState]);

	return (
		<div className="node-activity-comment-actions-container">
			<div className="node-activity-comment-actions" tabIndex={0} aria-label="Actions">
				<ul>
					<li className={isCommentAlreadyLiked ? "node-like-button disabled" : "node-like-button"}>
						<button disabled={isCommentAlreadyLiked} onClick={handleCommentLike}>
							<Icon
								className="comment-like-icon"
								name={isCommentAlreadyLiked ? "thumbs up" : "thumbs up outline"}
								size="small"
							/>
							<span>Like</span>
						</button>
						<span
							className="comment-section-like-number"
							tabIndex={0}
							aria-label={likeCountAriaLabel}
							role="none"
						>
							&nbsp;{likeNumber}{" "}
						</span>
					</li>
					{/* Reply functionality disabled until next PR */}
					<li>
						<button className="action-links" onClick={() => props.handleReplyExpand(props.id)}>
							Reply
						</button>
					</li>
					<li>
						{props.nodeType ? (
							<NodeActivityContextMenu
								nodeId={props.id}
								nodeType={props.nodeType}
								disabled={props.isEditing}
							/>
						) : null}
					</li>
				</ul>
			</div>
		</div>
	);
};
