import React from "react";

/**
 * Contract for the Clear form Field Props.
 */
export interface IFieldClearProps {
	value?: undefined | null | string | number | [] | string[] | boolean;
	onClear: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

/**
 * Form field clear selection button (anchor).
 *
 * @param props IFieldClearProps
 * @returns React.FC<IFieldClearProps>
 */
export const FieldClear: React.FC<IFieldClearProps> = (props) => {
	const isPopulatedArray = Array.isArray(props.value) && props.value.length > 0;

	return undefined !== props.value &&
		props.value !== null &&
		((typeof props.value === "string" && props.value !== "") ||
			isPopulatedArray ||
			Number.isInteger(props.value)) ? (
		<a
			className="link-button"
			href=""
			onClick={(event) => {
				event.preventDefault();
				props.onClear(event);
			}}
		>
			Clear Selection
		</a>
	) : null;
};
