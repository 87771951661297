import { IArticleFormData } from "../../contracts/article/articleForm";
import {
	DeleteActionModel,
	UserActivityModel,
	StickyNodeRequest,
	UpdateNodeStatusActionModel
} from "../../contracts/models";
import { NodeActionTypes, NodeType } from "../../contracts/models/strikeEnums";
import { base64Encode } from "../../lib/strikeLibrary";
import { NodePreprocessingAPI } from "../base/NodePreprocessingAPI";
import { ServiceHub } from "../base/ServiceHub";

/**
 * Node Administrative tooling services class.
 */
export class NodeAdminAPI extends NodePreprocessingAPI {
	constructor() {
		super("nodeadmin");
	}

	/**
	 * Creates a Node with provided information, and specified type.
	 * Also, prepares the body to be transferred by using base-64 string encoding.
	 * As a precaution, also uploads any base-64 images detected in the body
	 * to be provided via the storage services.
	 *
	 * @param data The Node data to request creation.
	 * @param type The Node type to request update.
	 * @param actionType The Node Action Type to log.
	 */
	async createNode(data: IArticleFormData, type: NodeType, actionType: NodeActionTypes): Promise<IArticleFormData> {
		const dataCopy = { ...data };

		dataCopy.type = type;
		dataCopy.actionType = actionType;
		dataCopy.authorUpn = await ServiceHub.userProfileAPI.start().GetCurrentUserUpn();

		//Detects any body HTML base-64 image to upload it to services
		//As well as prepares it for transfer as a base-64 encoded string.
		dataCopy.body = base64Encode(await this.detectBodyImages(dataCopy.body));
		await this.processCoverImage(dataCopy);
		// Checks whether there are New Topics (before submitting)
		if (type === NodeType.Kbentry) {
			const topicsAddSucceeded = await this.checkAddedTopics(dataCopy);

			if (!topicsAddSucceeded) {
				return null;
			}
		}

		return await this.post("create", dataCopy)
			.then((result: any) => {
				if (result?.HasError) {
					throw Error(result.ErrorMessage);
				}

				// TODO: Show a toast success message
				return result.Value;
			})
			.catch((error) => {
				const errorMessage = this.constructErrorMessage(error);
				ServiceHub.message.error(errorMessage);

				throw Error(errorMessage);
			});
	}

	/**
	 * Updates a Node with provided information, and specified type.
	 * Also, prepares the body to be transferred by using base-64 string encoding.
	 * As a precaution, also uploads any base-64 images detected in the body
	 * to be provided via the storage services.
	 *
	 * @param data The Node data to request update.
	 * @param type The Node type to request update.
	 * @param actionType The Node Action Type to log.
	 */
	async updateNode(data: IArticleFormData, type: NodeType, actionType: NodeActionTypes): Promise<IArticleFormData> {
		const dataCopy = { ...data };

		dataCopy.type = type;
		dataCopy.actionType = actionType;
		dataCopy.authorUpn = await ServiceHub.userProfileAPI.start().GetCurrentUserUpn();

		//Detects any body HTML base-64 image to upload it to services
		//As well as prepares it for transfer as a base-64 encoded string.
		dataCopy.body = base64Encode(await this.detectBodyImages(dataCopy.body));
		await this.processCoverImage(dataCopy);
		// Checks whether there are New Topics (before submitting)
		if (type === NodeType.Kbentry) {
			const topicsAddSucceeded = await this.checkAddedTopics(dataCopy);

			if (!topicsAddSucceeded) {
				return null;
			}
		}

		return await this.put("update", dataCopy)
			.then((result: any) => {
				if (result.HasError) {
					throw Error(result.ErrorMessage);
				}

				// TODO: Show a toast success message
				return result.Value;
			})
			.catch((error) => {
				const errorMessage = this.constructErrorMessage(error);
				ServiceHub.message.error(errorMessage);

				throw error;
			});
	}

	/**
	 * Creates an Article with provided information.
	 * Uses createNode behind to process data.
	 *
	 * @param data The Article data to request creation.
	 */
	async createArticle(data: IArticleFormData): Promise<IArticleFormData> {
		return await this.createNode(
			data,
			NodeType.Kbentry,
			data.id !== null && data.id !== "" ? NodeActionTypes.Revise : NodeActionTypes.NewKbentry
		);
	}

	/**
	 * Updates an Article based on provided info.
	 * Uses updateNode behind to process data.
	 *
	 * @param data The Article data to store.
	 */
	async updateArticle(data: IArticleFormData): Promise<IArticleFormData> {
		return await this.updateNode(data, NodeType.Kbentry, NodeActionTypes.Revise);
	}

	/**
	 * Creates a Topic with provided information.
	 * Uses createNode behind to process data.
	 *
	 * @param data The Topic data to request creation.
	 */
	async createTopic(data: IArticleFormData): Promise<IArticleFormData> {
		return await this.createNode(
			data,
			NodeType.Topic,
			data.id !== null && data.id !== "" ? NodeActionTypes.Revise : NodeActionTypes.NewTopic
		);
	}

	/**
	 * Updates a Topic based on provided info.
	 * Uses updateNode behind to process data.
	 *
	 * @param data The Topic data to store.
	 */
	async updateTopic(data: IArticleFormData): Promise<IArticleFormData> {
		return await this.updateNode(data, NodeType.Kbentry, NodeActionTypes.NewTopic);
	}

	/**
	 * Executes a check on whether the current user
	 * logged in can consume NodeAdmin services.
	 *
	 * @returns
	 */
	async reviewAccess(): Promise<boolean> {
		return await this.get("ReviewAccess")
			.then(() => {
				return true;
			})
			.catch(() => {
				return false;
			});
	}

	/**
	 * Uploads a Cover Image to Strike's blob storage.
	 * Gets a string, which should be base-64 encoded to submit to the services.
	 *
	 * @param base64ImageContent
	 * @param onUploaded
	 */
	async coverImageUpload(
		base64ImageContent: string,
		fileName: string,
		onUploaded: (filePath: string) => void | Promise<void>
	) {
		const fData = new FormData();

		fData.append("file", base64ImageContent);
		fData.append("fileName", fileName);

		await this.post("coverImage/upload", fData).then((response) => {
			if (typeof onUploaded === "function") onUploaded(response.toString());
		});
	}

	/**
	 * Uploads an Attachment from a Node Body, as part of the HTML.
	 * Gets a string File Path which must be base-64 encoded, with the location of the stored file.
	 *
	 * @param base64ImageContent
	 * @param onUploaded
	 */
	async attachmentUpload(
		base64ImageContent: string,
		fileName: string,
		onUploaded: (filePath: string) => void | Promise<void>
	) {
		const fData = new FormData();

		fData.append("file", base64ImageContent);
		fData.append("fileName", fileName);

		await this.post("attachments/upload", fData).then((response) => {
			if (typeof onUploaded === "function") onUploaded(response.toString());
		});
	}

	/**
	 * @param nodeId
	 * @param actionType
	 * Delete node action
	 */
	async deleteNode(nodeId: string, actionTypeObject: DeleteActionModel): Promise<boolean> {
		return await this.delete(`delete/${nodeId}`, actionTypeObject)
			.then((result) => true)
			.catch(() => false);
	}

	/**
	 * @param nodeId
	 * @param actionType
	 * UpdateNodeStatus action for delete and makePrivate
	 */
	async UpdateNodeStatus(actionTypeObject: UpdateNodeStatusActionModel): Promise<boolean> {
		return await this.delete(`UpdateNodeStatus/`, actionTypeObject)
			.then((result) => true)
			.catch(() => false);
	}

	/**
	 * @param publishTypeObject
	 * Publish Article action
	 */
	async publishArticle(publishTypeObject: UserActivityModel): Promise<boolean> {
		return await this.post("publishArticle", publishTypeObject)
			.then((result) => true)
			.catch(() => false);
	}

	/**
	 * From an existing Article Form Data,
	 * picks the Added Topics and pre-upload
	 * them for post-storage in Article.
	 *
	 * @param data The Article data to check for newly Added Topics.
	 * @returns boolean
	 */
	async checkAddedTopics(data: IArticleFormData): Promise<boolean> {
		if (data.addedTopics.length === 0) return true;

		return await ServiceHub.topicsAPI
			.start()
			.create(data.addedTopics)
			.then(() => {
				return true;
			})
			.catch((error) => {
				// Handle error case
				ServiceHub.message.error(error);

				return false;
			});
	}

	/**
	 * @param nodeId
	 * @param spaceId
	 * Stick Article to Space or Website
	 */
	async addStickNode(stickyNodeModel: StickyNodeRequest): Promise<boolean> {
		return await this.post(`sticky/add`, stickyNodeModel)
			.then((result) => true)
			.catch(() => false);
	}

	/**
	 * @param nodeId
	 * @param spaceId
	 * Remove Stick Article to Space or Website
	 */
	async removeStickNode(stickyNodeModel: StickyNodeRequest): Promise<boolean> {
		return await this.post(`sticky/remove`, stickyNodeModel)
			.then((result) => true)
			.catch(() => false);
	}
}
