import { IStrikeNodes_Default } from "../../../contracts/models";
import { IFilterSlice } from "../../../contracts/slices";

/**
 * The Initial State of Node Management slice.
 */
export const filterInitialState: IFilterSlice = {
	data: {
		searchTopics: "",
		searchTitle: "",
		pageIndex: 1,
		nodeList: IStrikeNodes_Default
	},
	ui: {
		expanded: false,
		loading: false,
		loaded: false,
		nodesLoading: true,
		dashboardButtonTitle: "Dashboards",
		dashboardNavigation: undefined,
		topicsResetKey: 0,
		spaceSelectedValue: undefined,
		isFilterVisible: false,
		isDashboardLoading: false
	}
};
