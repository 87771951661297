import React, { useMemo } from "react";
import { TextArea } from "semantic-ui-react";

/**
 * Controls the input properties of TextArea
 */
export interface ICustomTextAreaProps {
	type?: undefined | string;
	id?: undefined | string;
	placeholder?: undefined | string;
	value?: undefined | string;
	required?: undefined | boolean;
	error?: undefined | boolean;
	minLength?: undefined | number;
	maxLength?: undefined | number;
	onChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => Promise<void> | void;
}

/**
 * A custom pre-styled text area component to use in Strike app forms.
 *
 * @param props ICustomTextAreaProps
 * @returns React.FC<ICustomTextAreaProps>
 */
export const CustomTextArea: React.FC<ICustomTextAreaProps> = (props) => {
	const resolvedType = useMemo(() => {
		return props.type ?? "text";
	}, [props.type]);

	return (
		<TextArea
			type={resolvedType}
			id={props.id}
			placeholder={props.placeholder}
			value={props.value}
			onChange={props.onChange}
			className="form-control custom-text-area"
			required={props?.required ?? undefined}
			error={props?.error ?? undefined}
			input={{
				minLength: props?.minLength ?? undefined,
				maxLength: props?.maxLength ?? undefined
			}}
		/>
	);
};
