import React from "react";
import { Nav, Navbar } from "react-bootstrap";

import headerLogo from "../../StrikeLogo.svg";
import { MenuBuilder } from "./shared/menuBuilder";
import { MenuUserSection } from "./shared/menuUserSection";
import { ServiceHub } from "../../service";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ApplicationRoutePaths } from "../router";
import { appManagementSelectors } from "../../redux/selectors/appManagement";
import { useEffect } from "react";
import { setIsHighZoomLevel } from "../../redux/reducers/appManagement";

/**
 * Application Header Menu.
 *
 * @returns React.ReactElement
 */
const HeaderMenu = (): React.ReactElement => {
	const dispatch = useDispatch();
	const isHighZoomLevel = useSelector(appManagementSelectors.getIsHighZoomLevel);
	const bannerVisible = useSelector(appManagementSelectors.getBannerVisible);

	useEffect(() => {
		const checkZoomLevel = () => {
			dispatch(setIsHighZoomLevel(ServiceHub.appManagementAPI.isHighZoomLevel()));
		};

		checkZoomLevel();

		window.addEventListener("scroll", ServiceHub.appManagementAPI.handleZoomLevelScroll);
		window.addEventListener("resize", ServiceHub.appManagementAPI.handleZoomLevelScroll);
		return () => {
			window.removeEventListener("resize", ServiceHub.appManagementAPI.handleZoomLevelScroll);
			window.removeEventListener("scroll", ServiceHub.appManagementAPI.handleZoomLevelScroll);
		};
	}, []);

	const handleHomeNavigation = () => {
		ServiceHub.appManagementAPI.resetDashboardFilters();
		ServiceHub.filterAPI.start().setDashboardNavigation(undefined);
		ServiceHub.appMenuAPI.start().resetAllMenu();
	};

	return (
		<section
			className={`header-container ${isHighZoomLevel ? "high-zoom-level" : ""} ${
				!bannerVisible ? "banner-not-visible" : ""
			}`}
		>
			<Navbar className="container" expand="lg" variant="dark" collapseOnSelect>
				<Navbar.Brand>
					<Link to={ApplicationRoutePaths.root()} onClick={handleHomeNavigation}>
						<img className="nav-link-logo" src={headerLogo} alt="Strike Logo" />
					</Link>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="navbar-toggle" />
				<Navbar.Collapse id="navbar-toggle">
					<Nav className="me-auto ">
						<MenuBuilder />
					</Nav>
				</Navbar.Collapse>
				<MenuUserSection />
			</Navbar>
		</section>
	);
};

export default HeaderMenu;
