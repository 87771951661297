import React from "react";
import { useDispatch } from "react-redux";
import { useFilterSlice } from "../../../../hooks/filter/useFilterSlice";
import { Icon } from "@fluentui/react/lib/Icon";

/**
 * Interface for Space Details Box component
 */
interface ISpaceDetailsBoxProps {}

/**
 * Box to use on node list Space Dashboard
 * to show Space details info such coverImage,
 * spaceName or description.
 * @param props
 * @returns
 */
export const SpaceDetailsBox: React.FC<ISpaceDetailsBoxProps> = (props) => {
	const dispatch = useDispatch();

	const { spaceCoverImage, spaceCoverImageLoaded, spaceName, spaceDescription } = useFilterSlice();

	return (
		<div className="space-details-box" tabIndex={0} aria-label="Space Details Box">
			<div className="space-details-box-headline-wrapper">
				<div className="space-details-box-headline">
					<h4 tabIndex={0}>{spaceName}</h4>
				</div>
				<div
					className="space-details-box-image-wrapper"
					tabIndex={0}
					aria-label={`Cover Image: ${spaceName}`}
					role="img"
				>
					{spaceCoverImage && spaceCoverImageLoaded ? (
						<img
							className="space-details-box-image"
							src={spaceCoverImage}
							alt={`${spaceName} cover image`}
							title={`${spaceName} cover image`}
						/>
					) : (
						<Icon iconName="HomeGroup" className="space-details-box-icon" />
					)}
				</div>
			</div>
			<div className="ui divider" />
			{spaceDescription ? (
				<div className="space-details-box-description">
					<p tabIndex={0}>{spaceDescription} </p>
				</div>
			) : null}
		</div>
	);
};
