import React, { useEffect, useState } from "react";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import { Icon } from "semantic-ui-react";
import { INodeInfo } from "../../contracts/models";
import { Link } from "react-router-dom";
import { mapNodeActions, utcToLocalDateTimeString } from "../../lib/strikeLibrary";
import "./node.summary.grid.css";
import defaultThumb from "../../strike-video-default.png";
import { useMemo } from "react";

/**
 * getArticleCoverImage
 *
 * @description This method convert a image string path to a base64 image url.
 *
 * @param {string} coverImage image path to convert.
 * @returns {string} base64 image url
 */

interface Props {
	Index: number;
	NodeInfo: INodeInfo;
}

export const NodeSummaryGrid: React.FunctionComponent<Props> = (props) => {
	const [coverImageSource, setCoverImageSource] = useState("");
	let imageData = props.NodeInfo.CoverImageThumbnail;
	useEffect(() => {
		if (imageData) {
			getArticleCoverImage(imageData);
		}
	}, [imageData]);

	/**
	 * Pre-maps the latest Node action within the info.
	 */
	const nodeActionsMap = useMemo(() => {
		return mapNodeActions(props.NodeInfo.LastActivity_ActionType);
	}, [props.NodeInfo]);

	const actionMap: { [key: string]: string } = {
		commented: "commented",
		newkbentry: "created article",
		answered: "answered",
		asked: "created question",
		answeredtocomment: "commented"
	};

	const getActionName = (actionKey: string) => {
		return actionMap[actionKey] ?? actionKey;
	};

	const getArticleCoverImage = async (coverImage: string) => {
		let res = await NodeAPIServiceInstance.getNodeCoverImageThumbnail(coverImage);
		if (res) {
			setCoverImageSource(res);
		}
	};

	return (
		<div key={props.Index} className="node-summary-grid-list-item">
			<div className="node-list-box card node-summary-grid">
				{/* article thumb image coming from api */}
				<Link to={`/articles/${props.NodeInfo.NodeId}/${props.NodeInfo.Title}`} rel="noopener noreferrer">
					{coverImageSource && props.NodeInfo.CoverImageThumbnail ? (
						<img
							className="node-summary-grid-image"
							alt={"Cover thumbnail for article" + props.NodeInfo.Title}
							src={coverImageSource}
						/>
					) : (
						<img className="node-summary-grid-image" alt="Default cover image." src={defaultThumb} />
					)}
				</Link>
				<div className="node-summary-grid-content-container">
					<div className="node-summary-grid-title-container">
						<h5 className="fs-12 mb-1 node-summary-grid-title-wrapper">
							<Icon name="book" size="small" color="grey" />
							<Link
								className="primary-link"
								to={`/articles/${props.NodeInfo.NodeId}/${props.NodeInfo.Title}`}
								rel="noopener noreferrer"
							>
								{props.NodeInfo.Title}
							</Link>
						</h5>
					</div>
					<div className="node-summary-grid-author">
						<p className="text-muted last-activity-user mb-1">
							<span className="bold-600-text">{props.NodeInfo.LastActivity_UserFullName}</span>
							{" " + getActionName(nodeActionsMap)}
							<span className="bold-700-text">.</span>
							<span>{" " + utcToLocalDateTimeString(props.NodeInfo.LastActivity_ActionDate)}</span>
						</p>
					</div>
				</div>
				<div className="p-3 text-end">
					<p className="text-muted last-activity-user mb-1">
						<Icon name="thumbs up" size="small" /> <b>{props.NodeInfo.LikeCount}</b>
					</p>
				</div>
			</div>
		</div>
	);
};
