import React from "react";
import { IAlertProps } from "../../../contracts";

const validCategories = ["info", "warning", "success", "danger"];

/* The purpose for this parameter 'category' its to be able to
 * customize the colors of the message notification,
 * being by default (if not custom value its passed) an alert-info look and feel message.
 * Another available values could be: warning, success or danger,
 * depending of the subject of the message to display.
 */
export const Alert: React.FunctionComponent<IAlertProps> = (props) => {
	const category = validCategories.includes(props.category) ? props.category : "info";

	return <div className={`mt-2 alert alert-${props.category}`}>{props.text}</div>;
};

Alert.defaultProps = {
	category: "info"
};
