import { generatePath } from "react-router-dom";

/**
 * Prepares and returns
 * The current application URL,
 * complete.
 *
 * @returns string with full current location Path.
 */
export function getCurrentUrl(): string {
	const url = window.location.href;

	return `${url}`;
}

/**
 * Prepares a Text string to be used within a URL,
 * safely encoded as URI Component.
 *
 * @param text The text to encode.
 * @returns Encoded text, as well as <empty-space> and <+> signs get replaced by dashes.
 */
export function prepareUrlText(text: string): string {
	return encodeURIComponent(text.replaceAll(" ", "-").replaceAll("+", "-"));
}

/**
 * Maintains a centralized path configuration set.
 * For the application navigation and routing configuration.
 */
export const RoutePathStrings = {
	article: "/articles/:id/:title?/*",
	articleCreate: "/articles/create",
	articleEdit: "/articles/edit/:id?",
	attachmentDownload: "/storage/attachments/:filePath",
	dashboard: "/Dashboard",
	// eventRegistration: "/registration/*",
	// eventCheckIn: "/registration/check-in",
	event: `/event/:id/:title?/*`,
	eventSearch: `/event/search`,
	eventCreate: `/event/create`,
	eventEdit: `/event/edit/:id?`,
	eventSettings: `/event/settings/:id?`,
	eventCheckIn: `/event/check-in`,
	question: `/questions/:id/:title?/*`,
	questionCreate: `/questions/create`,
	questionEdit: `/questions/edit/:id?`,
	spaces: `/spaces/:id/:title?/*`,
	spaceManage: `/spaces/manage`,
	spaceCreate: `/spaces/create`,
	spaceEdit: `/spaces/edit/:id?`,
	spacesDashboard: `/spaces`,
	topicsDashboard: `/topics`,
	topics: `/topics/:id/:title?/*`,
	questionsDashboard: `/questions`,
	articlesDashboard: `/articles`,
	root: "/",
	any: "*"
};

/**
 * Maintains a centralized path attribution set
 * For the application navigation and routing configuration.
 */
export const ApplicationRoutePaths = {
	article: (id: string, title: undefined | string = "") =>
		generatePath(RoutePathStrings.article, {
			id,
			title: !title ? "" : prepareUrlText(title)
		}),
	articleCreate: () => RoutePathStrings.articleCreate,
	articleEdit: (id: string) => generatePath(RoutePathStrings.articleEdit, { id }),
	attachmentDownload: (filePath: string) => generatePath(RoutePathStrings.attachmentDownload, { filePath }),
	dashboard: () => RoutePathStrings.dashboard,
	// eventRegistration: () => RoutePathStrings.eventRegistration,
	// eventCheckIn: () => RoutePathStrings.eventCheckIn,
	event: (id: string, title: undefined | string = "") =>
		generatePath(RoutePathStrings.event, { id, title: !title ? "" : prepareUrlText(title) }),
	eventCreate: () => RoutePathStrings.eventCreate,
	eventSearch: () => RoutePathStrings.eventSearch,
	eventEdit: (id: string) => generatePath(RoutePathStrings.eventEdit, { id }),
	eventSettings: (id: string) => generatePath(RoutePathStrings.eventSettings, { id }),
	eventCheckIn: () => RoutePathStrings.eventCheckIn,
	question: (id: string, title: undefined | string = "") =>
		generatePath(RoutePathStrings.question, { id, title: !title ? "" : prepareUrlText(title) }),
	questionCreate: () => RoutePathStrings.questionCreate,
	questionEdit: (id: string) => generatePath(RoutePathStrings.questionEdit, { id }),
	spaces: (id: string, title: undefined | string = "") =>
		generatePath(RoutePathStrings.spaces, { id, title: !title ? "" : prepareUrlText(title) }),
	spaceManage: () => RoutePathStrings.spaceManage,
	spaceCreate: () => RoutePathStrings.spaceCreate,
	spaceEdit: (id: string) => generatePath(RoutePathStrings.spaceEdit, { id }),
	spacesDashboard: () => RoutePathStrings.spacesDashboard,
	topicsDashboard: () => RoutePathStrings.topicsDashboard,
	topics: (id: string, title: undefined | string = "") =>
		generatePath(RoutePathStrings.topics, { id, title: !title ? "" : prepareUrlText(title) }),
	questionsDashboard: () => RoutePathStrings.questionsDashboard,
	articlesDashboard: () => RoutePathStrings.articlesDashboard,
	root: () => RoutePathStrings.root,
	any: () => RoutePathStrings.any
};
