import React, { useEffect, useRef, useState, FormEvent } from "react";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from "@fluentui/react/lib/DetailsList";
import { Button, IconButton } from "../../common/button";
import { ServiceHub } from "../../../service";
import { ApplicationRoutePaths } from "../../router";
import { useSearchParams } from "react-router-dom";
import { useEventForm } from "../../../hooks/event/useEventForm";

/**
 * Controls the input of the Share Content
 * Form component.
 */
interface IEventAllowUsersListProps {}

/**
 * Event Search Content Liist
 *
 * @param props IEventtAllowUsersListProps
 * @returns React.FC<IEventtAllowUsersListProps>
 */
export const EventAllowUsersList: React.FC<IEventAllowUsersListProps> = (props) => {
	const eventFormService = useRef(ServiceHub.eventForm.start());
	const { eventsList, allowedUserSelection } = useEventForm();

	const handleAllowedUserRemove = (emailId: string) => {
		eventFormService.current.removeUserFromAllowedList(emailId);
		eventFormService.current.setIsAllowUsersChanged(true);
	};

	const columns: IColumn[] = [
		{
			key: "column1",
			name: "Name",
			fieldName: "name",
			minWidth: 180,
			maxWidth: 180,
			isResizable: true
		},
		{
			key: "column2",
			name: "Email",
			fieldName: "emailId",
			minWidth: 150,
			maxWidth: 150,
			isResizable: true
		},
		{
			key: "column7",
			name: "",
			fieldName: "",
			minWidth: 100,
			maxWidth: 120,
			isResizable: true,
			onRender: (item) => {
				return (
					<div className="event-search-list-actions">
						<IconButton
							iconName="Delete"
							ariaLabel="Delete"
							onClick={() => handleAllowedUserRemove(item.emailId)}
						/>
					</div>
				);
			}
		}
	];
	return (
		<>
			{allowedUserSelection.length !== 0 ? (
				<div className="event-alloweduser-container">
					<DetailsList
						items={allowedUserSelection}
						columns={columns}
						setKey="set"
						layoutMode={DetailsListLayoutMode.justified}
						selectionPreservedOnEmptyClick={false}
						selectionMode={SelectionMode.none}
						ariaLabelForSelectionColumn="Toggle selection"
						checkButtonAriaLabel="select row"
						className="event-allowedusers-list"
					/>
				</div>
			) : (
				<div className="event-alloweduser-container">
					<p>No Users allowed yet.</p>
				</div>
			)}
		</>
	);
};
