import React from "react";
import { PivotItem } from "@fluentui/react";
import { IPaginationProps, OverflowOption, Pagination } from "@coherence-design-system/pagination/lib";

interface Props {
	totalPages: number;
	onPageChange: (pageIndex: number, pageNumber?: number | null) => void;
	pageLength?: undefined | number;
	page?: undefined | number;
}
//export const StrikePagination = (): React.ReactElement => {
export const StrikePagination: React.FunctionComponent<Props> = (props): React.ReactElement => {
	const totalPages = props.totalPages;

	//const itemsPerPageList: IComboBoxNumberOption[] = [
	//  { key: 5, text: '5' },
	//  { key: 10, text: '10' },
	//  { key: 15, text: '15' },
	//  { key: 20, text: '20' }
	//];

	// DataGrid setup
	//const [previewData, setPreviewData] = React.useState<string[][]>([]);
	//const [isLoadingTable, setIsLoadingTable] = React.useState<boolean>(false);

	// Pagination setup
	const [currentOverflowOption, setCurrentOverflowOption] = React.useState<OverflowOption | undefined>();
	const [currentPage, setCurrentPage] = React.useState(props?.page ?? 1);
	const [currentPageSize, setCurrentPageSize] = React.useState(props?.pageLength ?? 10);

	const handleOnPageChange = (_event: React.MouseEvent<HTMLButtonElement>, selectedPage: number): void => {
		const startRowValue = currentPageSize * (selectedPage - 1) + 1;
		const endRowValue = startRowValue - 1 + currentPageSize;
		setCurrentPage(selectedPage);
		props.onPageChange(selectedPage);
	};

	const handleOverflowClick: NonNullable<IPaginationProps["overflowButtonProps"]>["onClick"] = (_event) => {
		const updateOverflow = currentOverflowOption ? undefined : "textfieldAndPageDropdown";
		setCurrentOverflowOption(updateOverflow);
	};

	const handlePivotClick = (
		pivot?: PivotItem | undefined,
		_ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined
	): void => {
		const currentNumber = parseInt((pivot && pivot.props.headerText) || "1");
		const startRowIndex = currentPageSize * (currentNumber - 1) + 1;
		const endRowIndex = startRowIndex + currentPageSize - 1;
		setCurrentPage(currentNumber);
		props.onPageChange(currentNumber);
	};

	//const handleOnItemsPerPageChange = (_event: React.FormEvent<IComboBoxNumberOption>, pageNumber: number): void => {
	//  props.onPageChange(currentPage, pageNumber);
	//};

	const paginationProps: IPaginationProps = {
		previousPage: {
			ariaLabel: "Previous Page"
		},
		nextPage: {
			ariaLabel: "Next Page"
		},
		overflowButtonProps: {
			onClick: handleOverflowClick
		},
		pageControls: {
			currentPage: currentPage,
			pages: totalPages,
			overflowOption: currentOverflowOption,
			onPivotClick: handlePivotClick
		},
		onPageChange: handleOnPageChange
		//itemsPerPageProps: {
		//  currentPageSize: currentPageSize,
		//  itemsPerPageList: itemsPerPageList,
		//  onItemsPerPageChange: handleOnItemsPerPageChange
		//}
	};

	return totalPages > 1 ? <Pagination {...paginationProps} /> : <div></div>;
};
