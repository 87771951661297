import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { commentsSelectors } from "../../../../redux/selectors/comments";
import { getQueryUserActivityObject, validateEmail } from "../../../../lib/strikeLibrary";
import { NodeActionTypes, NodeType } from "../../../../contracts/models/strikeEnums";
import { useAuthUser } from "../../../../hooks";
import {
	addLike,
	removeLike,
	resetCommentsToShow,
	setCommentsToShow,
	setIsNodeAlreadyLiked,
	setToggleCommentForm,
	setToggleCommentQuestionForm
} from "../../../../redux/reducers/comments";
import { authSelectors } from "../../../../redux/selectors/auth";
import { NodeAPIServiceInstance } from "../../../../service/NodeAPI";
import { UserActivityModel } from "../../../../contracts/models";
import nodeActivityConstants from "./constants";

/**
 * Interface for comments main actions component
 */
interface INodeActivityActionsProps {
	nodeId: number;
	type: NodeType;
}

/**
 * Comments Actions component
 * to handle Node Like, Comment and Show toggle
 * @param props
 * @returns
 */
export const NodeActivityActions: React.FC<INodeActivityActionsProps> = (props) => {
	const dispatch = useDispatch();
	const authoriableUserId = useSelector(authSelectors.getAuthoriableId);

	const [likeTriggered, setLikeTriggered] = useState(false);
	const [voteDown, setVoteDown] = useState(false);
	const isNodeLikeMatching = useSelector(commentsSelectors.getNodeAlreadyLiked);
	const [isNodeLikeMatchingHandler, setIsNodeLikeMatchingHandler] = useState(isNodeLikeMatching);

	const likeCount = useSelector(commentsSelectors.getNodeLikeCount);
	const allNodeComments = useSelector(commentsSelectors.getAllNodeComments);
	const isHideButton = useSelector((state) => commentsSelectors.getShouldHideAll(state, props.nodeId));
	const rootComments = useSelector((state) => commentsSelectors.getRootNodeComments(state, props.nodeId));
	const rootCommentsTypeAnswer = useSelector((state) =>
		commentsSelectors.getRootNodeCommentsByTypeAnswer(state, props.nodeId)
	);
	const rootCommentsTypeComment = useSelector((state) =>
		commentsSelectors.getRootNodeCommentsByTypeComment(state, props.nodeId)
	);
	const ipAddress = "";
	const { userPrincipalName } = useAuthUser();
	const likesArray = useSelector(commentsSelectors.getNodeLikeIds);
	const isQuestion = props.type === NodeType.Question;

	/**
	 * Part of User Interface
	 * Triggering redux state to change boolean flag
	 * It is reseting pagination showing all comments (show all)
	 * or going back to initial state(hide all).
	 */
	const showAllCommentsToggle = () => {
		// Hide mode, when showing all the items
		if (isHideButton) {
			dispatch(resetCommentsToShow());
		} else {
			// Show all mode, when not
			dispatch(setCommentsToShow(rootComments.length));
		}

		// dispatch(setShowAllComments());
	};

	const toggleCommentForm = () => {
		dispatch(setToggleCommentForm());
	};

	const toggleCommentQuestionForm = () => {
		dispatch(setToggleCommentQuestionForm());
	};

	/**
	 * Handle live event activity and mocking like values on the fly.
	 * @param userActivityModel
	 */
	const logLikeEventActivity = async (userActivityModel: UserActivityModel) => {
		let res = await NodeAPIServiceInstance.logUserActivity(userActivityModel);
		if (await res) {
			if (res) {
				if (!voteDown) {
					dispatch(addLike(authoriableUserId));
				} else if (voteDown) {
					dispatch(removeLike(authoriableUserId));
				}

				monitorLikeState();
			}
		}
	};

	/**
	 * Handle live event action triggered by 'Like' button
	 */
	const handleNodeLikeEvent = () => {
		setLikeTriggered(true);

		if (validateEmail(userPrincipalName)) {
			const actionType = voteDown ? NodeActionTypes.VoteDown : NodeActionTypes.VoteUp;

			const queryLikeActivity = getQueryUserActivityObject(
				actionType,
				ipAddress,
				props.nodeId.toString(),
				userPrincipalName
			);
			logLikeEventActivity(queryLikeActivity);
		}
		setVoteDown(!voteDown);
	};

	/**
	 * Monitor if the authId for a user its present in the LikeByUsers list
	 * If there is match means user already liked it the node and action should be disabled.
	 */
	const monitorLikeState = () => {
		if (likesArray.length === 0 || !authoriableUserId || authoriableUserId === 0) {
			dispatch(setIsNodeAlreadyLiked(false));
			return;
		}
		dispatch(setIsNodeAlreadyLiked(likesArray.includes(parseInt(authoriableUserId))));
	};

	useEffect(() => {
		monitorLikeState();

		// redux true & like trigered false -> base case -> cant like
		if (isNodeLikeMatching && !likeTriggered) {
			setIsNodeLikeMatchingHandler(true);
		}
		// redux false & like triggered false -> base case -> can like
		else if (!isNodeLikeMatching && !likeTriggered) {
			setIsNodeLikeMatchingHandler(false);
		}
		// redux true & like triggered true -> was clicked here -> can dislike and like till component unmount
		else if (isNodeLikeMatching && likeTriggered) {
			setIsNodeLikeMatchingHandler(false);
		}
	}, [monitorLikeState]);

	const likeNumberAriaLabel = `${likeCount === 0 ? "No" : likeCount} like${likeCount === 1 ? "" : "s"}`;

	const totalCommentCountAriaLabel = ` comment${allNodeComments.length === 1 ? "" : "s"}`;

	const answersCountAriaLabel = `${
		rootCommentsTypeAnswer.length === 0
			? "No answers"
			: rootCommentsTypeAnswer.length === 1
			? `${rootCommentsTypeAnswer.length} answer`
			: `${rootCommentsTypeAnswer.length} answers`
	}`;

	const commentCountAriaLabel = `${
		rootCommentsTypeComment.length === 0
			? "No comments"
			: rootCommentsTypeComment.length === 1
			? `${rootCommentsTypeComment.length} comment`
			: `${rootCommentsTypeComment.length} comments`
	}`;

	return (
		<div className="node-activity-main-actions">
			<ul>
				<li className={isNodeLikeMatchingHandler ? "node-like-button disabled" : "node-like-button"}>
					<button disabled={isNodeLikeMatchingHandler} onClick={handleNodeLikeEvent}>
						<Icon
							name={isNodeLikeMatchingHandler ? "thumbs up" : "thumbs up outline"}
							className="node-like-icon"
							size="small"
						/>
						<span>Like</span>
					</button>
					<span
						className="comment-section-like-number"
						tabIndex={0}
						aria-label={likeNumberAriaLabel}
						role="none"
					>
						&nbsp;{likeCount}
					</span>
				</li>
				{!isQuestion && rootComments.length > nodeActivityConstants.paginationOffset ? (
					<li>
						<Icon name="comments" size="small" />
						<button onClick={showAllCommentsToggle}>{isHideButton ? "Hide all" : "Show all"}</button>
						<span className="comment-section-comments-number">{allNodeComments.length}</span>
					</li>
				) : !isQuestion && allNodeComments.length ? (
					<li tabIndex={0} aria-label={totalCommentCountAriaLabel}>
						<strong>{allNodeComments.length}</strong>{" "}
						{allNodeComments.length === 1 ? "comment" : "comments"}
					</li>
				) : null}
				{isQuestion && rootComments.length > 0 ? (
					<li>
						<div
							className="comment-section-show-question-count"
							tabIndex={0}
							aria-label={answersCountAriaLabel}
							role="none"
						>
							<strong>{rootCommentsTypeAnswer.length} </strong>
							{rootCommentsTypeAnswer.length === 1 ? "answer" : "answers"}
						</div>
						<div
							className="comment-section-show-question-count"
							tabIndex={0}
							aria-label={commentCountAriaLabel}
							role="none"
						>
							<strong>{rootCommentsTypeComment.length} </strong>
							{rootCommentsTypeComment.length === 1 ? "comment" : "comments"}
						</div>
					</li>
				) : null}
			</ul>
		</div>
	);
};
