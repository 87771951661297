import React from "react";
import { createSelector } from "reselect";
import { IDropdownOption, IGroup } from "@fluentui/react";
import { ISpaceItem } from "../../contracts";
import { DropdownItemProps } from "semantic-ui-react";
import { ITreeItem } from "@coherence-design-system/tree-view";
import { ApplicationRoutePaths } from "../../components/router";
import { ServiceHub } from "../../service";

/**
 * Article Form data access selectors.
 */
export const spacesSelectors = {
	getSelectedId: (state): null | number | string => {
		return state.spaces.selectedId;
	},
	getSpacesList: (state): ISpaceItem[] => {
		return state.spaces.spaces;
	},
	getSelectedItem: createSelector(
		(state) => state,
		(state) => state.spaces.selectedId,
		(state, selectedId) => {
			let spaceById;
			const selectedSpaceId = selectedId;
			const loaded = spacesSelectors.getLoaded(state);

			if (!selectedSpaceId || !loaded) return null;

			spaceById = spacesSelectors.getSpaceById(state, selectedSpaceId);

			if (!spaceById || spaceById?.Title === "") return null;

			return {
				id: selectedSpaceId.toString(),
				text: spaceById?.Title
			};
		}
	),
	getSpaceById: createSelector(
		(state) => state,
		(_, itemId: number | string) => itemId,
		(state, itemId): ISpaceItem => {
			if (!itemId || itemId === "") return null;

			return state.spaces.spaces.find((item) => item.Id === parseInt(itemId));
		}
	),
	getParentSpacesList: createSelector(
		(state) => state,
		(state): ISpaceItem[] => {
			return state.spaces.spaces.filter((space) => space.ParentId === null || space.ParentId === "");
		}
	),
	getExpandedSpacesById: createSelector(
		(state) => state,
		(_, spaceId: number | string) => spaceId,
		(state, spaceId: number | string): boolean => {
			return state.spaces.expandedSpaces.includes(spaceId);
		}
	),
	getChildrenSpacesDropdownOptions: createSelector(
		(state) => state,
		(_, parentId: string | number) => parentId,
		(state, parentId: string | number): ITreeItem[] => {
			return state.spaces.spaces
				.filter((space) => space.ParentId === parentId)
				.map((item) => spacesSelectors.makeSpacesDropdownOption(state, item));
		}
	),
	getAllParentsSpaces: createSelector(
		(state) => state,
		(_, parentId: string | number) => parentId,
		(state, parentId: string | number): ITreeItem[] => {
			return state.spaces.spaces
				.filter((space) => space.ParentId === parentId)
				.map((item) => spacesSelectors.makeSpacesDropdownOption(state, item));
		}
	),
	getParentSpacesDropdownOptions: createSelector(
		(state) => state,
		(state): ITreeItem[] => {
			return state.spaces.spaces
				.filter((space) => space.ParentId === null || space.ParentId === "")
				.map((item) => spacesSelectors.makeSpacesDropdownOption(state, item));
		}
	),
	makeSpacesDropdownOption: createSelector(
		(state) => state,
		(_, item: ISpaceItem) => item,
		(state, item: ISpaceItem): ITreeItem => {
			const selectedSpaceId = state.spaces.selectedId;

			return {
				id: item.Id,
				title: item.Title,
				children: spacesSelectors.getChildrenSpacesDropdownOptions(state, item.Id),
				isExpanded: spacesSelectors.getExpandedSpacesById(state, item.Id),
				isSelected: selectedSpaceId === item.Id,
				onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, treeItem: ITreeItem) => {
					// Updates the state layer by adding the selected item as current selected space
					const spacesSvc = ServiceHub.spacesAPI.start();
					const previousSelectedId = spacesSvc.getSelectedId();
					let newShape: null | number | string;

					if (!previousSelectedId || previousSelectedId === "" || previousSelectedId !== treeItem.id) {
						newShape = treeItem.id;
						spacesSvc.setSelectedId(newShape);
						spacesSvc.setExpandedSpaceById(parseInt(treeItem.id), true);
					} else {
						// In this case, it is unselecting the space
						newShape = "";
						spacesSvc.setSelectedId(newShape);
						spacesSvc.setExpandedSpaceById(parseInt(treeItem.id), false);
					}

					spacesSvc.setOpen(false);

					const route = ApplicationRoutePaths.spaces(treeItem.id, treeItem.title);
					ServiceHub.appManagementAPI.navigate(route);
				}
			} as ITreeItem;
		}
	),
	getCoverImageTypesDropdownOptions: createSelector(
		(state) => state,
		(state): IDropdownOption[] => {
			return state.spaces.list.coverImageTypes.map((item) => ({ value: item.Id, text: item.Name }));
		}
	),
	getTopicsComboBoxOptions: createSelector(
		(state) => state,
		(_, externalItems: string[]) => externalItems,
		(state, externalItems: string[]): DropdownItemProps[] => {
			const resultantCollection = [
				...externalItems,
				...state.spaces.list.topics.filter((topic: string) => !externalItems.includes(topic))
			];

			return resultantCollection.map((item) => ({ value: item, text: item }));
		}
	),
	getLoading: (state): boolean => state.spaces.loading ?? false,
	getLoaded: (state): boolean => state.spaces.loaded ?? false,
	getOpen: (state): boolean => state.spaces.open ?? false,
	getSpaceManagerItemsList: (state): ISpaceItem[] => state.spaces.manager.items,
	getSpaceManagerGroupsList: (state): IGroup[] => state.spaces.manager.groups,
	getIsOpenDeleteConfirmation: (state): boolean => state.spaces.manager.ui.deleteConfirmationOpen,
	getSpaceToDelete: (state): string => state.spaces.manager.ui.spaceToDelete,
	getSpaceIdToDelete: (state): string => state.spaces.manager.ui.spaceIdToDelete,
	getCurrentParentSpace: (state): string => state.spaces.manager.ui.currentParentSpace,
	getSpaceCoverImage: (state): string => state.spaces.spaceCoverImage,
	getSpaceCoverImageLoaded: (state): boolean => state.spaces.spaceCoverImageLoaded,
	getSpaceName: (state): string => state.spaces.spaceName,
	getSpaceDescription: (state): string => state.spaces.spaceDescription,
	getSpaceDetailsLoaded: (state): boolean => state.spaces.spaceDetailsLoaded,
	getParentSpaces: createSelector(
		(state) => state,
		(state): ITreeItem[] => {
			return state.spaces.spaces
				.filter((space) => space.IsParent === true)
				.map((item) => spacesSelectors.convertParentSpacesToOptions(state, item));
		}
	),
	convertParentSpacesToOptions: createSelector(
		(state) => state,
		(_, item: ISpaceItem) => item,
		(state, item: ISpaceItem): ITreeItem => {
			return {
				id: item.Id,
				title: item.Title,
				onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, treeItem: ITreeItem) => {
					ServiceHub.spaceForm.start().setSelectedParentId(treeItem.id);
				}
			} as ITreeItem;
		}
	)
};
