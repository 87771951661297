import React, { useMemo } from "react";

/**
 * Contract for the Rich Text component.
 */
export interface IRichTextNodeProps {
	children: any | string;
}

/**
 * Reusable component to render rich text html
 *
 * @param props
 * @returns
 */
export const RichTextNode: React.FC<IRichTextNodeProps> = (props) => {
	const resolvedChildren = useMemo(() => {
		if (Array.isArray(props.children))
			return props.children.filter((line) => {
				if (line.trim() !== "") return line;
			});

		return props.children;
	}, [props.children]);
	const ariaLabel = useMemo(() => {
		return `Body Section content: ${resolvedChildren}`;
	}, [resolvedChildren]);

	return resolvedChildren ? (
		<div dangerouslySetInnerHTML={{ __html: resolvedChildren }} aria-label={ariaLabel} role="region" tabIndex={0} />
	) : null;
};
