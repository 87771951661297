import React, { useCallback, useEffect, useState } from "react";
import { AuthorMention } from "../../article";
import { RichTextNode } from "../richTextNode";
import { IStrikeNodesComments } from "../../../../contracts/models";
import { utcToLocalDateTimeString } from "../../../../lib/strikeLibrary";
import { ServiceHub } from "../../../../service";

interface INodeActivityCommentBodyProps {
	comment: IStrikeNodesComments;
}

/**
 * Comment Body to show comments elements
 *
 * @param props
 * @returns
 */
export const NodeActivityCommentBody: React.FC<INodeActivityCommentBodyProps> = ({ comment }) => {
	const [commentBody, setCommentBody] = useState<string>(comment.Title);
	const formattedDate = useCallback(
		(comment: IStrikeNodesComments) => utcToLocalDateTimeString(comment.CommentOn),
		[]
	);

	useEffect(() => {
		const parser = new DOMParser();
		const commentHtmlText = parser.parseFromString(comment.Title, "text/html");
		const imgElements = commentHtmlText.getElementsByTagName("img");
		if (imgElements) {
			for (let i = 0; i < imgElements.length; i++) {
				const imageSource = imgElements[i].getAttribute("src");

				if (imageSource && !imageSource.includes("data:application/octet-stream;base64")) {
					/**
					 * Removing defaut storage folder paths
					 * and getting only filename.
					 */
					ServiceHub.appFileAPI
						.start()
						.getAttachmentFileByURL(imageSource.replace("storage/attachments/", ""))
						.then((data: void | Blob) => {
							if (data) {
								const reader = new FileReader();
								reader.readAsDataURL(data);

								reader.onloadend = function () {
									const base64data = reader.result;
									imgElements[i].setAttribute("src", base64data.toString());
									setCommentBody(commentHtmlText.documentElement.outerHTML);
								};
							}
						});
				}
			}
		}
	}, [comment.Title]);

	return (
		<div className={"node-activity-comment"}>
			<div className="node-activity-comment-author">
				<AuthorMention
					authEmail={comment.CommentByEmail}
					authName={comment.CommentByName}
					createdOn={formattedDate(comment)}
				/>
			</div>
			<div className="node-activity-comment-text">
				<RichTextNode>{commentBody}</RichTextNode>
			</div>
		</div>
	);
};
