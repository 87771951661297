/**
 * Redux config to manage the app
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { spacesInitialState } from "../../models/slices";
import { ISpaceItem } from "../../contracts";
import { IGroup } from "@fluentui/react";

/**
 * Spaces List state management layer, also named after a Redux Slice.
 */
export const spacesSlice = createSlice({
	name: AppSlices.spaces,
	initialState: spacesInitialState,
	reducers: {
		setSelectedId: (state, action: PayloadAction<null | number | string>) => {
			state.selectedId = action.payload;
		},
		setSpacesList: (state, action: PayloadAction<ISpaceItem[]>) => {
			state.spaces = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setLoaded: (state, action: PayloadAction<boolean>) => {
			state.loaded = action.payload;
		},
		setExpandedSpaceById: (state, action: PayloadAction<{ spaceId: number; expanded: boolean }>) => {
			if (action.payload.expanded && !state.expandedSpaces.includes(action.payload.spaceId)) {
				state.expandedSpaces = [...state.expandedSpaces, action.payload.spaceId];
			} else if (!action.payload.expanded && state.expandedSpaces.includes(action.payload.spaceId)) {
				state.expandedSpaces = state.expandedSpaces.filter((item) => item !== action.payload.spaceId);
			}
		},
		setOpen: (state, action: PayloadAction<boolean>): void => {
			state.open = action.payload;
		},
		resetExpandedSpaces: (state) => {
			state.expandedSpaces = spacesInitialState.expandedSpaces;
		},
		reset: (state) => {
			state.selectedId = spacesInitialState.selectedId;
			state.expandedSpaces = spacesInitialState.expandedSpaces;
			state.loading = spacesInitialState.loading;
			state.loaded = spacesInitialState.loaded;
			state.spaces = spacesInitialState.spaces;
		},
		setSpaceManagerItemsList: (state, action: PayloadAction<ISpaceItem[]>) => {
			state.manager.items = action.payload;
		},
		setSpaceManagerGroupsList: (state, action: PayloadAction<IGroup[]>) => {
			state.manager.groups = action.payload;
		},
		setIsOpenSpaceManagerDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
			state.manager.ui.deleteConfirmationOpen = action.payload;
		},
		setSpaceManagerSpaceToDelete: (state, action: PayloadAction<string>) => {
			state.manager.ui.spaceToDelete = action.payload;
		},
		setSpaceManagerSpaceIdToDelete: (state, action: PayloadAction<string>) => {
			state.manager.ui.spaceIdToDelete = action.payload;
		},
		resetSpaceManagerSpaceToDelete: (state) => {
			state.manager.ui.spaceToDelete = spacesInitialState.manager.ui.spaceToDelete;
		},
		setManagerCurrentParentSpace: (state, action: PayloadAction<string>) => {
			state.manager.ui.currentParentSpace = action.payload;
		},
		setSpaceCoverImage: (state, action: PayloadAction<string>) => {
			state.spaceCoverImage = action.payload;
		},
		setSpaceName: (state, action: PayloadAction<string>) => {
			state.spaceName = action.payload;
		},
		resetSpaceCoverImage: (state) => {
			state.spaceCoverImage = spacesInitialState.spaceCoverImage;
		},
		resetSpaceName: (state) => {
			state.spaceName = spacesInitialState.spaceName;
		},
		setSpaceCoverImageLoaded: (state, action: PayloadAction<boolean>) => {
			state.spaceCoverImageLoaded = action.payload;
		},
		setSpaceDescription: (state, action: PayloadAction<string>) => {
			state.spaceDescription = action.payload;
		},
		resetSpaceDescription: (state) => {
			state.spaceDescription = spacesInitialState.spaceDescription;
		},
		setSpaceDetailsLoaded: (state, action: PayloadAction<boolean>) => {
			state.spaceDetailsLoaded = action.payload;
		}
	}
});

export const spacesActions = spacesSlice.actions;

export default spacesSlice.reducer;
