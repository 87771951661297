import { IFollowRelation } from "../../contracts";

/**
 * Definition of selectors
 * for Follow Slice.
 */
export const followSelectors = {
	getFollowersToShow: (state): number => state.follow.ui.followersToShow,
	getFollowers: (state): IFollowRelation[] => state.follow.data.followers,
	getFollowersLength: (state): number => state.follow.data.followers?.length,
	getQuerying: (state): boolean => state.follow.ui.querying,
	getQueried: (state): boolean => state.follow.ui.queried,
	getCurrentUserFollowing: (state, currentUserUpn: string) => {
		return (
			state.follow.data.followers?.length > 0 &&
			currentUserUpn !== null &&
			currentUserUpn != "" &&
			state.follow.data.followers?.some((follower) => follower.UserUPN === currentUserUpn)
		);
	},
	getFollowerNameById: (state, upn: string): string =>
		state.follow.data.followers.find((follower) => follower.UserUPN === upn)?.UserName ?? ""
};
