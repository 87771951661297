import * as React from "react";
import { ShowQuestion } from "./show.question";

/**
 * Component Question which servs a platform for the content to appear on the screen
 */
const Question = (): React.ReactElement => {
	return <ShowQuestion />;
};

export default Question;
