import React from "react";
import { ContextualMenu, Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { Button } from "../button";
import Constants from "../../../constants/Constants";
import { ServiceHub } from "../../../service";
import { ApplicationRoutePaths } from "../../router";
import { useDispatch } from "react-redux";
import { setIsOpenDeleteConfirmation } from "../../../redux/reducers/nodes";
import { useNodeActionsSlice } from "../../../hooks/node/useNodeActions";

const modalPropsStyles = {
	main: { maxWidth: 450, textAlign: "left" },
	footer: { display: "flex", justifyContent: "right" }
};

const modalFooterPropsStyles = {
	actions: { display: "flex", justifyContent: "right", marginTop: "30px" }
};

/**
 * Global Confirmation Message component contract.
 */
export interface IConfirmationMessageProps {
	blockScreen?: undefined | boolean;
	draggable?: undefined | boolean;
	onAction?: () => void;
	onClose?: () => void;
	text: string;
	title: string;
	isOpen: boolean;
	isActionConfirmed?: boolean;
}

/**
 * Confirmation message component.
 * Activation based on parent props.
 *
 * @param props IConfirmationMessageProps
 * @returns React.FC<IConfirmationMessageProps>
 */
export const ConfirmationMessage: React.FC<IConfirmationMessageProps> = (props) => {
	const dispatch = useDispatch();
	const confirmationMessageData = useNodeActionsSlice();
	const confirmationButtonText = confirmationMessageData.confirmationModalButtonText;
	const dragOptions = React.useMemo(
		() => ({
			moveMenuItemText: "Move",
			closeMenuItemText: "Close",
			menu: ContextualMenu
		}),
		[]
	);
	const modalProps = React.useMemo(
		() => ({
			isBlocking: props.blockScreen ?? true,
			styles: modalPropsStyles,
			dragOptions: props.draggable ? dragOptions : undefined
		}),
		[props]
	);

	const dialogContentProps = React.useMemo(
		() => ({
			type: DialogType.close,
			title: props.title,
			subText: props.text
		}),
		[props.title, props.text]
	);

	const onReturn = () => {
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.root());
		dispatch(setIsOpenDeleteConfirmation(false));
	};

	return (
		<Dialog
			hidden={!props.isOpen}
			onDismiss={props.onClose}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			{!props.isActionConfirmed ? (
				<DialogFooter styles={modalFooterPropsStyles}>
					<Button
						text={confirmationButtonText ? confirmationButtonText : "OK"}
						variation="primary"
						onClick={props.onAction}
					/>
					<Button text="Cancel" variation="secondary" onClick={props.onClose} />
				</DialogFooter>
			) : (
				<DialogFooter styles={modalFooterPropsStyles}>
					<Button
						text={Constants.deleteConfirmationTexts.DeleteGoToDashboard}
						variation="primary"
						onClick={onReturn}
					/>
				</DialogFooter>
			)}
		</Dialog>
	);
};
