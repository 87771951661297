import React from "react";
import { ArticlesNodesList } from "./articles.nodelist";

/**
 * Dashboard Component, in which all nodes are displayed, serves as a platform to see entire comunities, courses, etc
 */
const ArticlesDashboard = (): React.ReactElement => {
	return <ArticlesNodesList />;
};

export default ArticlesDashboard;
